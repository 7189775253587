import React, { useState } from 'react'
import { useWindowDimensions,View } from 'react-native'
import {Text,Layout,Button } from '@ui-kitten/components';
import {CompleteAnimation} from "../../components/animation"
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const OnboardingComplete = (props) => {
  const dimensions = useWindowDimensions();
  const {height,width} = dimensions
  const isSmallScreen = width < 500

        return (
         <Layout style={{
          position: 'relative',
          height:200,
          width: isSmallScreen ? '90%' : '70%',
          backgroundColor: '#fff',
          justifyContent: 'center',
          alignSelf:'center',
          margin: 10,
          padding:10,
          flex:1
          }} level='1' >

        <KeyboardAwareScrollView style={{flex:1,position: 'relative',width:'100%'}}       
        keyboardShouldPersistTaps="always">


          { isSmallScreen ? 
         <Layout style={{
          justifyContent: 'center',
          alignItems:'center',
          alignContent:'center',
          flex:1
          }} level='1' >
          <Text styles={{alignSelf:'center'}} category='h5'> Congratulations! </Text>
           <View style={{width:200,height:200}}>
            <CompleteAnimation/>
            </View>
 
           <Layout style={{
            justifyContent: 'center',
            alignItems:'flex-start',
            padding:20
            }} level='1' >
            <Text category='h6'> Next Up</Text>

            <Text category='s1'>1. Set calendar schedules </Text>
            <Text category='p'>Search and add a location first, click to edit the calendar</Text> 

            <Text category='s1'>2. Quick mode </Text>
            <Text category='p'>Set your current location live now or up to 2 hours in advance </Text> 

            <Text category='s1'>3. Preview mode </Text>
            <Text category='p'>Preview profile, menu and schedules on FoodPops, refresh if needed</Text>

            <Text category='s1'>4. Settings </Text>
            <Text category='p'>Update profile, menu and subscriptions </Text>

            </Layout>

            <Button style={{marginLeft:20, marginRight:20,alignSelf:'stretch'}} status='info' size='small' onPress={()=> props.complete(true)}>
              Finish
            </Button>
            </Layout>
            :

         <Layout style={{
          justifyContent: 'center',
          alignItems:'center',
          alignContent:'center',
          flex:1
          }} level='1' >
           <Text styles={{alignSelf:'center'}} category='h1'> Congratulations! </Text>
           <View style={{width:200,height:200}}>
            <CompleteAnimation/>
            </View>
 
            <Layout style={{
            justifyContent: 'center',
            alignItems:'flex-start',
            padding:20
            }} level='1' >
            <Text category='h4'>Next Up</Text>

            <Text category='s1'>1. Set calendar schedules </Text>
            <Text category='p'>Search and add a location first, click to edit the calendar</Text> 

            <Text category='s1'>2. Quick mode </Text>
            <Text category='p'>Set your current location live now or up to 2 hours in advance </Text> 

            <Text category='s1'>3. Preview mode </Text>
            <Text category='p'>Preview profile, menu and schedules on FoodPops, refresh if needed</Text>

            <Text category='s1'>4. Settings </Text>
            <Text category='p'>Update profile, menu and subscriptions </Text>


            </Layout>

            <Button style={{margin:50,alignSelf:'stretch'}} status='info' size='giant' onPress={()=> props.complete(true)}>
              Finish
            </Button>
            </Layout>
          }
         </KeyboardAwareScrollView>
        </Layout>
        );
    }


export default OnboardingComplete;
