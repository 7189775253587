import React, { useEffect, useState } from 'react';
import { useForm,Controller } from 'react-hook-form';
import {SafeAreaView,ActivityIndicator, View, useWindowDimensions } from 'react-native'
import "./styles.css";
import styles from './styles';
import {ImageHelper} from '../../helper'
import { firebase } from '../../firebase/config'
import {FormInput} from '../../components'
import {CheckBox,Input,Card, Text,TopNavigation, TopNavigationAction } from '@ui-kitten/components';
import {Image} from 'react-native-elements';
import {MaterialIcons} from '@expo/vector-icons'


const MenuItemScreen = (props) => {
  const [image, setImage] = useState(null);
  const {handleSubmit, errors, reset,control, register,setValue,getValues} = useForm();
  const { navigation, route } = props;
  const {uid,id:docid,index,sectionID,item} = route.params
  const path = "users/" + uid + "/menu/" + sectionID 
  const storage = firebase.storage().ref();
  let imagePath = path + '/' + docid + '/default.jpeg' 
  const tags = "vegan,vegetarian,gluten-free,kosher,lactose-free,sugar-free".split(',');

  const dimensions = useWindowDimensions();
  const {height,width} = dimensions
  const isSmallScreen = width < 500

  //run only once
  useEffect(() => {

    if (item) {
      reset(item)
      if (item.uri) {
        setImage({"uri":item.uri})
      }
    }
    }, [])

  const pickImage = async () => {
    console.log('now pick')
    const result = await ImageHelper.pickImage()
    setImage(result);
    ImageHelper.resizeImage(result)
    .then( thumbnail => {
      ImageHelper.uploadImage(thumbnail.uri,imagePath)
      // getImage(imagePath)
    })
  };

  const onSubmit = data => {
    console.log(data)
    const timestamp = firebase.firestore.FieldValue.serverTimestamp();
    data['lastUpdated'] = timestamp
    if (image)  {
      data['imagePath'] = imagePath
    }
    data['id'] = docid
    data['index'] = index
    
    const docRef = firebase.firestore().doc(path + '/data/' + docid)
    docRef
        .set(data)
        .then(_doc => {
          console.log('successfully updated')
        })
        .catch((error) => {
            alert(error)
        });

    if (image)  {
      data['uri'] = image.uri
    }

    navigation.goBack()
    route.params.onReturn({data:data,sectionID:sectionID,index:index});

    
  }

const renderBackAction = () => (
    <React.Fragment>
      <TopNavigationAction icon={()=> (<MaterialIcons name='arrow-back' size={20} onPress={()=>navigation.goBack()}/>)}/>
    </React.Fragment>
  );

  return (
      <SafeAreaView style={{ flex: 1 }}>

      <TopNavigation title={item ? "Update item": "Add item"}
        alignment='center'
        accessoryLeft={renderBackAction}
      />


         <Card status='basic' style={{margin:10,padding:isSmallScreen ? 10 : 20,alignSelf:'center',width:isSmallScreen ? '100%' : '90%'}}>

            <form style={{ flex:1, width: isSmallScreen ? '100%' : '70%'}} 
            className = "MenuItem" 
            onSubmit={handleSubmit(onSubmit)}>


            <View style={{flexDirection: 'row',flexWrap: 'wrap',paddingTop: 10}}>

                      {image ? (
                          <Image source={{ uri: image.uri  }} 
                        style={{ width: 120, height: 120, margin:10 }} 
                        PlaceholderContent={<ActivityIndicator />}
                        onPress = {pickImage} 
                        />) : (
                        <Image style={{flex: 1}} source={{uri:require('../../../assets/addImage.png')}} 
                        style={{ width: 120, height: 120 }} 
                        PlaceholderContent={<ActivityIndicator />}
                        onPress = {pickImage} 
                        />
                        )}

                      <View style={{flex: 1, flexDirection: 'column'}}>
                        <FormInput 
                            name="name" 
                            label="Name" 
                            control = {control}
                            rules = {{required: "Name of the dish is required", maxLength: 40
                            }}
                            required = {true}
                            defaultValue=""
                            errorobj={errors}
                            {...props}
                            />

                        <FormInput 
                          name="price" 
                          label="Price" 
                          control = {control}
                          rules = {{required: "Price is required", maxLength: 10,pattern:
                          {value:/([0-9]*[.])?[0-9]+/i,message:'Not an valid price'}
                          }}
                          required = {true}
                          defaultValue=""
                          errorobj={errors}
                          {...props}
                          />
                       </View>                     
              </View>

      <Controller
        name="description"
        control={control}
          render={() => (
            <Input 
          value={getValues("description")}
          label="Description"
          multiline={true}
            textStyle={{ minHeight: 100 }}
            placeholder=''
            onChangeText={nextValue => {
            setValue("description",nextValue)
            } 
            }
        />
      )}
        rules={{required: false, maxLength: 400}}
        fullWidth={true}
        defaultValue=""
      />
    {errors.description?.type === "maxLength" &&  <p>Your input exceeds maxLength</p>}


    <fieldset className="paddingTop" >
        <legend>Tags</legend>
           <View style={{flexDirection: 'row',flexWrap: 'wrap',justifyContent: 'space-around'}}>
             {
              tags.map(
                (c,i) => 
                <View key={i} style={{flexDirection: 'row',flexWrap: 'wrap'}}>
                 <label key={c}>
                 <input className="checkboxStyle" type="checkbox" value={c} name="tag" ref={register} />
                 {c}</label>
               <Image source={{uri: require(`../../../assets/${c}-selected.png`)}} style={{float:'center',width: 15, height: 15}}/>
                  </View >
              )
            }
         </View>
        </fieldset>

    <input type="submit" value= {item? "update": "add"} id="profilesubmit"/>

    </form>
    </Card>
   </SafeAreaView>
    );
}




export default MenuItemScreen;