import React, { useState } from 'react'
import { Card,Input,Button,Text} from '@ui-kitten/components';
import { View,useWindowDimensions} from 'react-native'
import { firebase } from '../firebase/config'

const ContactUs = (props) => {
  const {uid} = props
  const [message,setMessage] = useState('')
  const [subject,setSubject] = useState('')

  const dimensions = useWindowDimensions();
  const {height,width} = dimensions
  const isSmallScreen = width < 500

  const adjustedWidth = isSmallScreen? '90%':400
const onPress = () => {
      const timestamp = firebase.firestore.FieldValue.serverTimestamp();

        firebase.firestore().collection('users/'+uid+'/support')
        .add({'subject':subject,'message':message,'created':timestamp,'lastUpdated':timestamp,'status':'open'})
        .then(
        console.log('successfully added it')
        )
        .catch((error) => {
        console.log(error)
        });

  props.onPress(true)
}
        return (
        <Card style={{flex:1,flexDirection:'column',width:adjustedWidth,
        justifyContent: 'space-between',alignItems:'center',margin:10,alignSelf:'center'}}>
        
        <Text style={{alignSelf:'center'}} category='h1' > Contact us </Text>
        <Input 
          style={{flex:1,width:adjustedWidth*0.75}}
          value={subject}
          label={<Text category='s1' > Subject </Text>}
          onChangeText={setSubject}/> 
        <Input 
          style={{flex:1,width:adjustedWidth*0.75}}
          value={message}
          label={<Text category='s1' > Message </Text>}
          multiline={true}
          textStyle={{ minHeight: 100 }}
          onChangeText={setMessage}/>
  
        <Button style={{alignSelf:'stretch',margin:20}} status='info' onPress={onPress}>
        Send
        </Button>
        </Card>

        );
    }


export default ContactUs;
