import React,{ useEffect} from 'react'
import {Avatar,Layout,Text, Button} from '@ui-kitten/components';
import {Image,ImageBackground,SafeAreaView,useWindowDimensions, StyleSheet,View} from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { firebase } from '../../firebase/config'
import {CalendarAnimation,LiveAnimation,MenuAnimation} from "../../components/animation"

export default function SignUpScreen({navigation}) {

const dimensions = useWindowDimensions();
const {height,width} = dimensions
const isSmallScreen = width < 500

const styles = StyleSheet.create({
	columnContainer: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'stretch',
    },
    rowContainer: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        paddingTop:0.03*width,
        paddingBottom:0.03*width
    },
    textContainer:{
    	flexDirection: 'column',
    	width:width*0.4,
        margin:0.03*width
    },
    avatar: {
    	margin: 8,
    },
   card: {
	    width:0.4*width,
	    height:0.4*width,
	    margin:0.03*width
    },
   title:{
	    color: "white",
	    fontSize: 0.04*width,
	    fontWeight: "bold",
	 },
})


const handleSignUp = () => {
	navigation.navigate('SignIn')
}


useEffect(() => {

const link = firebase.auth().isSignInWithEmailLink(window.location.href)
    if (link) {
    	console.log('redirect email')
    	handleSignUp()
	}
 },[]);

return (
	 <SafeAreaView style={{ flex: 1,height:height*0.1}}>
 		<KeyboardAwareScrollView style={{flex:1,position: 'relative',width: '100%',alignSelf:'center'}}       
        keyboardShouldPersistTaps="always">
            
    	<ImageBackground  imageStyle={{resizeMode: 'cover'}}
    	style= {{flex: 1,maxHeight:'100vh',minHeight:'100vh',justifyContent:'space-between'}} source={{uri:require('../../../assets/background.jpg')}} >


	        <View>

		        <View style={{flexDirection: 'row',justifyContent:'space-between',alignItems:'center'}} >
			        	<Avatar style={styles.avatar} shape='rounded' source={require('../../../assets/foodpops.png')}/>
				         { isSmallScreen ? (
				        	<View style={{flex: 1,flexDirection: 'column'}}>
    						<Text category='s1' status='primary'>
							FoodPops	 
							</Text>
							<Text category='s2' style={{color:'#fff'}}>
							for Food Truck Owners					
							</Text>
						   </View>
				         	) :
				         (
				           <View style={{flex: 1,flexDirection: 'column'}}>
    						<Text category='h5' status='primary'>
							FoodPops	 
							</Text>
							<Text category='h6' style={{color:'#fff'}}>
							for Food Truck Owners					
							</Text>
						</View>

				         )}
				
						<View style={{alignSelf:'flex-end',margin:30}}>
			        	<Button onPress={handleSignUp}>
				           SIGN UP
				        </Button>
				        </View>
		    	</View>


		        <View style={{justifySelf:'flex-start',alignSelf:'center'}} >
				        <Text style={styles.title}>
						Manage your food truck schedules 
					    </Text>
					    <Text style={styles.title}>
				 		on FoodPops	 
					    </Text>
				</View>
			</View>


	        <View style={{alignItems:'center',margin:30}} >
			        <Button onPress={handleSignUp}>
			           TRY FOR FREE
			        </Button>
				    <Text category='p1' style={{color:'white'}}>
			 		No credit card needed	 
				    </Text>
			</View>
        </ImageBackground>


  

        <View style={styles.columnContainer}>
	        <Layout style={styles.rowContainer} level='2'>
		        <View style={styles.card}>
		        <CalendarAnimation/>
		        </View>


		        <View style={styles.textContainer} >

		        		{ isSmallScreen ? 
				        <Text category='s1'>
				        Set schedules 
					    </Text>
					     : <Text category='h5'> 
					    Set schedules 
					    </Text>
				    	}

					    <Text category='p1'>
				 		Edit weekly schedules 
				 		on the map.  	 
					    </Text>
				</View>

	        </Layout>


	        <Layout style={styles.rowContainer} level='1'>

		        <View style={[styles.textContainer,{marginLeft:width*0.1}]} >

		        		{ isSmallScreen ? 
				        <Text category='s1' >
						Set trucks live 
					    </Text>
					     : <Text category='h5'> 
						Set trucks live 
					    </Text>
				    	}
					    <Text category='p1'>
						Set your truck live instantaneously
						with a tap.  
						</Text>
				</View>

			    <View style={styles.card}>
		        <LiveAnimation/>
		        </View>
	        </Layout>



	        <Layout style={styles.rowContainer} level='2'>

		        <View style={styles.card}>
		        <MenuAnimation/>
		        </View>


		        <View style={styles.textContainer} >


		        		{ isSmallScreen ? 
				        <Text category='s1'>
						Set Menu
					    </Text>
					     : <Text category='h5'> 
						Set Menu
					    </Text>
				    	}
					    <Text category='p1'>
				 		Show off your delicious
				 		menu to your customers.
					    </Text>
				</View>
	        </Layout>

			{ isSmallScreen ?

				<Layout style={{flexDirection: 'column',padding:10}} level='1'>
	        	<View style={{flexDirection: 'row',alignItems:'flex-start',justifyContent: 'center'}}>
 					<View style={{paddingLeft:20,paddingRight:20,flexDirection: 'row',alignItems:'flex-end',padding:5,margin:10}} >
				        
				        <Text category='h5'>
						$9.99
					    </Text>
				        <Text appearance='hint' category='s1'>
			 			/month
					    </Text>
					</View>


 					<View style={{flexDirection: 'column',margin:10,justifyContent: 'center',alignItems:'center'}} >
	 					<View style={{paddingLeft:20,paddingRight:20,flexDirection: 'row',alignItems:'flex-end',padding:5}} >
					        <Text category='h5'>
							$99.99
						    </Text>
					        <Text appearance='hint' category='s1'>
				 			/year
						    </Text>
						</View>

				        <Text appearance='hint' category='s1'>
							with two month free					    
						</Text>

					</View> 
				</View>


				<View style={{alignItems:'center',margin:15,padding:5}} >
				        <Button size='small' onPress={handleSignUp}>
				           Start a 7-Day Free Trial Now ->
				        </Button>
					    <Text category='s1' appearance='hint' style={{padding:5}}>
				 		 No credit card required	 
					    </Text>
				</View>

	        </Layout>
	        :
				<Layout style={{flexDirection: 'column',padding:20}} level='1'>
	        	<View style={{flexDirection: 'row',alignItems:'flex-start',justifyContent: 'center'}}>
 					<View style={{paddingLeft:50,paddingRight:50,flexDirection: 'row',alignItems:'flex-end',padding:5,margin:20}} >
				        
				        <Text category='h1'>
						$9.99
					    </Text>
				        <Text appearance='hint' category='h5'>
			 			/month
					    </Text>
					</View>


 					<View style={{flexDirection: 'column',margin:20,justifyContent: 'center',alignItems:'center'}} >
	 					<View style={{paddingLeft:50,paddingRight:50,flexDirection: 'row',alignItems:'flex-end',padding:5}} >
					        <Text category='h1'>
							$99.99
						    </Text>
					        <Text appearance='hint' category='h5'>
				 			/year
						    </Text>
						</View>

				        <Text appearance='hint' category='h6'>
							with two month free					    
						</Text>

					</View> 
				</View>


				<View style={{alignItems:'center',margin:30,padding:5}} >
				        <Button size='giant' onPress={handleSignUp}>
				           Start a 7-Day Free Trial Now ->
				        </Button>
					    <Text category='h6' appearance='hint' style={{padding:5}}>
				 		 No credit card required	 
					    </Text>
				</View>

	        </Layout>
	        	
	    }


	        <Layout level='2'>

				<View style={{flexDirection: 'row',alignItems:'center',justifyContent: 'center',padding:5}} >
					    <Button style={{backgroundColor:"rgba(255, 71, 95, 0)",paddingLeft:15,paddingRight:15}} size='small' status='basic' appearance='ghost'>
				          <a href="https://info970307.wixsite.com/foodpops/terms-of-service">Terms of service</a> 
				        </Button>
					    <Button style={{backgroundColor:"rgba(255, 71, 95, 0)",paddingLeft:15,paddingRight:15}} size='small' status='basic' appearance='ghost'>
				          <a href="https://info970307.wixsite.com/foodpops/privacy">Privacy policy</a> 
				        </Button>
					    <Button style={{backgroundColor:"rgba(255, 71, 95, 0)",paddingLeft:15,paddingRight:15}} size='small' status='basic' appearance='ghost'>
  						<a href="mailto:support@foodpopsllc.com">Contact us</a>
				        </Button>				        				        
				</View>

		        
	        </Layout>

		</View>
		</KeyboardAwareScrollView>
		</SafeAreaView>
	)

}