import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input} from '@ui-kitten/components';


export class SearchBox extends Component {
  static propTypes = {
    mapsapi: PropTypes.shape({
      places: PropTypes.shape({
        SearchBox: PropTypes.func,
      }),
      event: PropTypes.shape({
        clearInstanceListeners: PropTypes.func,
      }),
    }).isRequired,
    placeholder: PropTypes.string,
    onPlacesChanged: PropTypes.func,
  };

  static defaultProps = {
    placeholder: 'Search locations to add',
    onPlacesChanged: null,
  };

  constructor(props) {
    super(props);

    this.searchInput = React.createRef();
  }

  componentDidMount() {
    const {
      mapsapi: { places },
    } = this.props;


const center = this.props.map.getCenter()

const options = {
        componentRestrictions: { country: "us" },
        fields: ["formatted_address", "geometry", "name"],
        origin: center,
        strictBounds: false,
      };
    
    this.autocomplete = new places.Autocomplete(this.searchInput.current, options);
    // this.searchBox = new places.SearchBox(this.searchInput.current,options);
    this.autocomplete.addListener('place_changed', this.onPlacesChanged);
    this.autocomplete.bindTo("bounds",this.props.map);
  }

  componentWillUnmount() {
    const {
      mapsapi: { event },
    } = this.props;
    event.clearInstanceListeners(this.autocomplete);
  }

  onPlacesChanged = () => {
    const { onPlacesChanged } = this.props;
    if (onPlacesChanged) {
      //clear previous search text
      this.searchInput.current.value = ''
      onPlacesChanged(this.autocomplete.getPlace());
    }
  };

  render() {
    const { placeholder, onPlacesChanged,...rest } = this.props;

    return (
      <input
        ref={this.searchInput}
        placeholder={placeholder}
        type="text"
        {...rest} 
      />
    );
  }
}

export default SearchBox;