import React, {useState,useEffect} from 'react';
import {Modal,Divider,Card, List, ListItem, Button,Layout,Calendar,Text, Select, IndexPath, SelectItem} from '@ui-kitten/components';
import styles from './styles';
import { firebase } from '../../firebase/config'
import {Entypo,MaterialCommunityIcons} from '@expo/vector-icons'
import { View,useWindowDimensions } from 'react-native'
import {Picker} from '@react-native-picker/picker';
import * as geofire from 'geofire-common';
import { toast } from 'react-toastify';


const options = {year: 'numeric', month: '2-digit', day: '2-digit' }

const timelist= Array(48).fill(0).map((x,y) => (y*30)%60 ? Math.trunc(y/2)+':30' : `${y/2}:00`)
const deleteIcon = (props) => (
  <Entypo name='cross' size={20} />
);

const deleteMarkerIcon = (props) => (
  <MaterialCommunityIcons name='map-marker-remove-variant' color='red' size={20} />
);
const CalendarSelect = (props: any) => {

  const [date, setDate] = useState(new Date());
  const [selectedStartIndex, setStartSelectedIndex] = useState(22);
  const [selectedEndIndex, setEndSelectedIndex] = useState(28);
  const [selectedRecurringWeek, setRecurringWeek] = useState(0);
  const [alertVisible,setAlertVisible] = useState(false)

  const {marker,uid} = props;
  const ref = firebase.firestore().doc('users/'+props.uid+'/locations/' + marker.id)
  const docRef = ref.collection('calendar')
  const [openDays,setOpenDays] = useState([])
  const [minDate, setMinDate] = useState(new Date());
  const [loading, setLoading] = useState(true)
  const now = new Date();
  const dimensions = useWindowDimensions();
  const {height,width} = dimensions
  const isSmallScreen = width < 500


  useEffect(() => {
            docRef
            .get()
            .then((querySnapshot) => {
             const array = []
              querySnapshot.forEach((doc) => {
              let dict = doc.data()
              array[dict.date] = array[dict.date] ? [...array[dict.date],{'id':dict.id,'time':`${dict.timeStart}-${dict.timeEnd}`}] : [{'id':dict.id,'time':`${dict.timeStart}-${dict.timeEnd}`}]
               });
              setOpenDays(array)
              setLoading(false)
            })
            .catch((error) => {
              setLoading(false)
            });

},[]);


const handleSubmit = async () => {
      const start = timelist[selectedStartIndex]
      const end = timelist[selectedEndIndex]
      const datestring = date.toLocaleDateString("en-US",options)

      // if doc not exist
      const doc = await ref.get()
      const data = {
        geolocation:new firebase.firestore.GeoPoint(marker.lat, marker.lng),
        geohash:geofire.geohashForLocation([marker.lat, marker.lng]),
        name:marker.name,
        id:marker.id
      }

        if (openDays.length==0 && !doc.exists) {
          ref
          .set(data)
          .then(_doc => {
            console.log('successful')
          })
          .catch((error) => {
              console.log(error)
          });
        }

      for (let i = 0; i <= selectedRecurringWeek; i++) {
          const newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + i*7);
            const datestring = newDate.toLocaleDateString("en-US",options)
          // exist
          if (openDays[datestring] && openDays[datestring].filter(x=> x.time == `${start}-${end}`).length>0) {
            toast.warning(`${datestring} ${start}-${end} are added twice!`);
          } else {

            let newRecord = docRef.doc()
            const newTime = {timeStart:start,timeEnd:end,date:datestring,...data}
            newTime['id'] = newRecord.id

            let items = [...openDays]
            items[datestring] = items[datestring] ? [...items[datestring],{'id':newRecord.id,'time':`${start}-${end}`}] : [{'id':newRecord.id,'time':`${start}-${end}`}]
            setOpenDays(items)

              docRef.doc(newRecord.id)
              .set(newTime)
              .then(_doc => {
                console.log('successful')
              })
              .catch((error) => {
                  console.log(error)
              });
            toast.success(`${datestring} ${start}-${end} is added successfully!`);

            props.onSubmit(true)
          }
        }
}


const deleteItem = async (item) => {


      let items = [...openDays]
      const datestring = date.toLocaleDateString("en-US",options)
      items[datestring] = openDays[datestring].filter(x=> x.time != item.time)
      openDays[datestring] = openDays[datestring].filter(x=> x.time != item.time)

      setOpenDays(items)
        docRef.doc(item.id)
          .delete()
          .then(_doc => {
            console.log('successfully delete the time')
          })
          .catch((error) => {
              console.log(error)
          });
      

      let isEmpty = Object.entries(openDays).map(([key,value])=> value.length>0).includes(true)
      // check if it is empty
      if (!isEmpty) {
            ref
            .delete()
            .then(_doc => {
              console.log('successfully delete the marker')
            })
            .catch((error) => {
              console.log(error)
            });
      }  
      toast.success(`${datestring} ${item.time} is deleted successfully!`);
      props.onSubmit(true)

}

const deleteMarker = async () => {
    
    for (const day in openDays) {
      for (let i = 0; i < openDays[day].length; i++) {
          docRef.doc(openDays[day][i].id)
          .delete()
          .then(_doc => {
            console.log('successfully delete the time')
          })
          .catch((error) => {
              console.log(error)
          });
      }
    }


      ref
      .delete()
      .then(_doc => {
        console.log('successfully delete the marker')
      })
      .catch((error) => {
        console.log(error)
      });

      toast.success('Schdules are deleted successfully for this location!');

      props.onSubmit(true)
}



const DayCell = ({ date }, style) => (
  <Layout
    style={[styles.dayContainer, style.container]}>
    <Text style={style.text}>{`${date.getDate()}`}</Text>
    {
      (date.toLocaleDateString("en-US",options) in openDays) && 
      // openDays.includes(date.toLocaleDateString()) && 
        <Text style={[style.text,{position:'absolute',bottom:0,font:40,weight:800}]} category='h1'>
          .
        </Text>
    }
  </Layout>
);



if (loading) {  
  return null
}

const renderItem = ({ item, index }) => (
    <ListItem
      style={{height:20}}
      title={item.time}
      accessoryLeft={()=>renderItemAccessory(item)}
    />
  );
const renderItemAccessory = (item) => (
   <Button style={{margin:2,padding:0,backgroundColor:"rgba(255, 71, 95, 0)"}} appearance='ghost' accessoryLeft={deleteIcon} onPress={()=>deleteItem(item)}/>
);

  return (
      <Layout style={{flex:1,flexDirection: isSmallScreen? 'column' : 'row',alignItems: 'flex-start'}}>
      <React.Fragment>
          <Calendar
            date={date}
            onSelect={nextDate => setDate(nextDate)}
            renderDay = {DayCell}
            min={now} 
          />

      <Layout style={{flex:1,flexDirection: 'column',margin:10}}>



          <Layout style={{marginBottom:10}}>
         <Text status='info' category='h6' >
            Date: {date.toLocaleDateString("en-US",options)}
          </Text>
        <Text status='info' category='h6' >
            Address: {marker.name}
          </Text>
        </Layout>


        <Divider/>

       { (date.toLocaleDateString("en-US",options) in openDays) &&
          <Text status='info' style={{marginTop:10}}>
            Existing schedules
          </Text>
        }


        { (date.toLocaleDateString("en-US",options) in openDays) &&
            <Card>
            <List
            data={openDays[date.toLocaleDateString("en-US",options)]}
            renderItem={renderItem}
            />
            </Card>
        }
            

          <Text status='info' style={{marginTop:10}}>
            Add new schedules
          </Text>

            <Card>


              <Layout style={styles.rowContainer}>

              <Text>
              Time
              </Text>


              <Picker
              style={styles.text}
                selectedValue={timelist[selectedStartIndex]}
                onValueChange={(itemValue, itemIndex) => setStartSelectedIndex(itemIndex) }
               >
                {
                timelist.map(
                  (c,i) =>
                  <Picker.Item key={i} label={c} value={c} />
                  )
                }    
              </Picker>



                <Text>
                  to
                </Text>

                <Picker
                style={styles.text}
                selectedValue={timelist[selectedEndIndex]}
                onValueChange={(itemValue, itemIndex) => setEndSelectedIndex(itemIndex)}
               >
                {
                timelist.map(
                  (c,i) =>
                  <Picker.Item key={i} label={c} value={c} />
                  )
                }    
              </Picker>

                

              </Layout>


              <Layout style={styles.rowContainer}>
              <Text>
                Repeat for
              </Text>


              <Picker
              style={styles.text}
                selectedValue={selectedRecurringWeek+1}
                onValueChange={(itemValue, itemIndex) => setRecurringWeek(itemIndex)}
               >

               {
                  [...Array(8).keys()].map(
                    (c,i) =>
                  <Picker.Item key={i} label={c+1} value={c+1} />
                    )
                  }    
    
              </Picker>


                
                  <Text>
                    weeks
                  </Text>
                  </Layout>


                      <Layout style={styles.footerContainer}>
                         <Button 
                         size='small'
                         status='basic'
                         onPress={()=>{props.onSubmit(true)}}>
                            Cancel
                         </Button>

                        <Button 
                        size='small'
                         onPress={handleSubmit}>
                            Add
                        </Button>
                   </Layout>

        </Card>


      </Layout>
    </React.Fragment>
    <Button style={{position:'absolute',justifyContent: 'center',right:-15,top:-15,margin:2,padding:-10,backgroundColor:"rgba(255, 71, 95, 0)"}} 
      appearance='ghost' accessoryLeft={deleteMarkerIcon} onPress={()=>setAlertVisible(true)}/>


      <Modal
      visible={alertVisible}
      backdropStyle={styles.backdrop}>

      <Card status='basic'>
        <Layout style={{padding:10}}>
            <Text style={{paddingBottom:10}} status='basic' category='h4'>Delete location</Text>
            <Text status='basic' category='p'>Do you want to delete this location along with all the schedules in it?</Text>
            <Text status='basic' category='p'>You cannot undo this action.</Text>

            <Layout style={[styles.footerContainer,{margin:10}]}>
            
                <Button 
                 size='small' appearance='ghost' onPress={()=>setAlertVisible(false)}>
                  CANCEL
                 </Button>

                <Button 
                size='small' appearance='ghost' onPress={()=>{setAlertVisible(false);deleteMarker()}}>
                DELETE
                </Button>

            </Layout>
         </Layout>  
          </Card>

      </Modal>


          </Layout>

      );
};

export default CalendarSelect;