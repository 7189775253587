import React from 'react';
import './styles.css';

const Marker = (props: any) => {
  const { color, name, id } = props;
  const onClick = () => {
    props.onClick(props)
  };

  return (
            <div
              className="pin bounce"
              style={{ backgroundColor: color, cursor: 'pointer' }}
              title={name}
              onClick={onClick}
            />
      );
};

export default Marker;


