import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    overlay:{
    width: '60%',
    flex:1,
    flexDirection: 'column',
    justifyContent: 'center',
    margin: 10,
    alignItems: 'center',
    alignSelf:'center'
  },
  container:{
    position: 'relative',
    height: '70%',
    width: '70%',
    backgroundColor: '#fff',
    justifyContent: 'center',
    alignSelf:'center',
    alignItems:'center',
    margin: 10,
    padding:10,
    flex:1
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  controlBar: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 45,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  input: {
    flex: 1,
    margin: 2,
  },
  rowContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  controlContainer: {
    borderRadius: 4,
    margin: 2,
    padding: 6,
    backgroundColor: '#3366FF',
  },
  button: {
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 30,
        paddingRight: 30,
        backgroundColor: '#1860D6',                                    
        borderRadius: 10, 
        margin:20,
        alignItems:'center',
     },
  text:{
    color:'#fff',
    fontFamily: "Cochin",
    fontSize: 16
  },
  title:{
    fontFamily: "Cochin",
    fontSize: 40,
    fontWeight: 400
  },
    subtitle:{
    fontFamily: "Cochin",
    fontSize: 30,
    fontWeight: 400
  }

  });