import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    container: {
        flexDirection: 'row',
        flexWrap: 1,
    },
    avatar: {
    margin: 8,
  },
  text: {
    margin: 2,
    paddingBottom:0
  },
  topRight: {
    float:'right',
  },
   backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  row: {
    flexDirection: 'row',
    alignItems: 'start',
    justifyContent: 'space-between',
    margin: 2,

  },
  column: {
    flexDirection: 'column',
    alignItems: 'start',
  },
    cardContainer: {
        flex:1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        alignContent:'flex-start',
    },
    footerContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    headerContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        margin: 10,
    },
    contentContainer: {
    paddingHorizontal: 8,
    paddingVertical: 4,
  },
})
