import React, { useEffect, useState } from 'react';
import { useForm,Controller } from 'react-hook-form';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { firebase } from '../../firebase/config'
import { ImageHelper} from '../../helper'
import {FormInput} from '../../components'
import "./styles.css";
import {Image} from 'react-native-elements';
import { MaterialCommunityIcons, FontAwesome5 } from '@expo/vector-icons'
import { ActivityIndicator } from 'react-native';
import { Modal,Card, Input,Button,Layout, Divider,Text} from '@ui-kitten/components';
import { useWindowDimensions,View,Platform } from 'react-native'
import { ThemeActivityIndicator} from '../../styles';
import { toast } from 'react-toastify';
import { SelectPlan } from '../OnboardingScreen';

export default function ProfileScreen(props) {
  const [image, setImage] = useState(null);
  const {navigation,extraData} = props
  const {uid,onboarding,subscribed} = extraData
  const usersRef = firebase.firestore().collection('users').doc(uid+'/profile/default')
  const storage = firebase.storage().ref();
  const path = "users/" + uid + "/profile/ProfileImage.jpeg"
  const {handleSubmit, errors, register,reset, control} = useForm();
  const [loading, setLoading] = useState(false)

  const dimensions = useWindowDimensions();
  const {height,width} = dimensions
  const isSmallScreen = width < 500
  const imageSize = isSmallScreen ? 60 : 120
  const [subscribeVisible, setSubscribeVisible] = useState(false);

  console.log(extraData)

  useEffect(() => {
    //update UI with the latest input
    const observer = usersRef.onSnapshot(function(doc) {
      const data = doc.data()
      if (data) { 
        data['submit']='update';
        if (data.uri != '') {
        setImage({"uri":data.uri});
         }
        }
      reset(data)
    }, err => {
    // console.log(err) 
    });

    //request image access if not web
    (async () => {
      if (Platform.OS !== 'web') {
        const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (status !== 'granted') {
          alert('Sorry, we need camera roll permissions to make this work!');
        }
      }
    })();
    }, [reset])



  const pickImage = async () => {
    const result = await ImageHelper.pickImage()
    setImage(result);
    const thumbnail = await ImageHelper.resizeImage(result)
    await ImageHelper.uploadImage(thumbnail.uri,path)
  }

  const updateDB = (data) => {
        usersRef
        .set(data)
        .then(
        console.log(data)
        )
        .catch((error) => {
            alert(error)
        });
  }
  const onSubmit = data => {
    const timestamp = firebase.firestore.FieldValue.serverTimestamp();
    data['lastUpdated'] = timestamp

    if (image) {
        const ref = storage.child(path);
          ref
          .getDownloadURL()
          .then(function(url) {
            console.log(url)
            data['uri'] = url
            updateDB(data)
          }).catch(function(error) {
            console.log(error);
            // not finishing uploading yet
            data['uri'] = ''
            updateDB(data)
            console.log('too quick not finish uploading yet')
          });
    } else {
        data['uri'] = ''
        updateDB(data)
    }
    
    if ('onSubmitFinish' in props) {
    props.onSubmitFinish(true)
    } else {
    toast.success('Your truck profile is updated successfully!');
    }

  }

const changeSubscription = async () => {

    if (subscribed == 'trialing') {
            setSubscribeVisible(true)
    } else {
          setLoading(true)
         console.log('clicked')
          const functionRef = firebase
          .app()
          .functions('us-east4')
          .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
          const { data } = await functionRef({ returnUrl: window.location.origin });
          setLoading(false)
          window.location.assign(data.url);
    } 
 }


if (loading) {  
  return ( 
  <ThemeActivityIndicator /> 
  ) 
}


  return (
        <Layout style={{
          position: 'relative',
          height: isSmallScreen ? height-112 : 400,
          maxWidth:700,
          backgroundColor: '#fff',
          justifyContent: 'center',
          alignSelf:'center',
          margin: 0,
          padding:10,
          flex:1,
        }} level='1'>
        <KeyboardAwareScrollView style={{flex:1,position: 'relative',width: '100%',alignSelf:'center'}}       
        keyboardShouldPersistTaps="always">

        <Card status='basic' style={{margin:10,padding:isSmallScreen ? 10 : 20,alignSelf:'center',width:'100%',height:'100%'}} >
        <Layout style={{flex:1,flexDirection:'column',padding:isSmallScreen ? 10 : 20, alignItems:'center',justifyContent:'center'}}>

        { !onboarding &&
              <View style={{flex: 1, flexDirection: 'row', alignSelf:'flex-end'}}>
                <Button 
                style={{alignSelf:'flex-end'}}
               size='tiny'
               status='danger'
               appearance='outline'
               onPress={changeSubscription}>
               {subscribed == 'trialing' ? 'Subscribe' : 'Update plan' }
               </Button>
              </View>
        }
        <Text status='basic' category='h4'>Public truck profile</Text>

          <Divider/>

            <form className = "ProfileScreen" onSubmit={handleSubmit(onSubmit)}>


                  <View style={{flex: 1, flexDirection: 'row', alignItems:'center', alignSelf: 'center',paddingBottom:isSmallScreen ? 10 : 20}}>
                            {image ? (
                              <Image   source={{ uri: image.uri  }} 
                            style={{ marginLeft:10,width: imageSize, height: imageSize }} 
                            PlaceholderContent={<ActivityIndicator />}
                            onPress = {pickImage} 
                            />) : (
                            <Image  style={{flex: 1}} source={{uri:require('../../../assets/addImage.png')}} 
                            style={{ paddingLeft:10,width: imageSize, height: imageSize }} 
                            PlaceholderContent={<ActivityIndicator />}
                            onPress = {pickImage} 
                            />
                            )}

                    <View style={{flex:1,alignSelf:'stretch'}}>
                    <FormInput 
                    name="name" 
                    label="Truck name" 
                    Placeholder="required"
                    control = {control}
                    rules = {{required: "Name is required", minLength:1, 
                    maxLength: { value:40, message:'Maximum 40 characters'}}}
                    required={true}
                    defaultValue=""
                    errorobj={errors}
                    {...props}
                    />
                    </View> 
                  </View>


             <View style={{flex: 1, flexDirection: 'row', flexWrap:'wrap'}}>

                <FormInput 
                    name="phone" 
                    label="Phone Number" 
                    leftIcon = 'phone'
                    control = {control}
                    rules = {{required: false, pattern: 
                      {value:/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, message:'Not a valid phone number'}
                    }}
                    defaultValue=""
                    errorobj={errors}
                    {...props}
                    />


                <FormInput 
                    name="email" 
                    label="Email" 
                    leftIcon ='email'
                    control = {control}
                    rules = {{required: false, pattern: 
                      {value:/^\S+@\S+$/i,message:'Not an email address'}
                    }}
                    defaultValue=""
                    errorobj={errors}

                    {...props}
                    />

                <FormInput 
                    name="facebook" 
                    label="Facebook" 
                    leftIcon = 'facebook'
                    control = {control}
                    rules = {{required: false, maxLength: 100, pattern: 
                      {value:/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/i,message:'Not an valid URL'}
                    }}
                    defaultValue=""
                    errorobj={errors}

                    {...props}
                    />



               <FormInput 
                    name="twitter" 
                    label="Twitter" 
                    leftIcon = 'twitter'
                    control = {control}
                    rules = {{required: false, maxLength: 100,pattern:
                      {value:/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/i,message:'Not an valid URL'}
                    }}
                    defaultValue=""
                    errorobj={errors}

                    {...props}
                    />



               <FormInput 
                    name="instagram" 
                    label="Instagram" 
                    leftIcon = 'instagram'
                    control = {control}
                    rules = {{required: false, maxLength: 100,pattern:
                      {value:/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/i,message:'Not an valid URL'}
                    }}
                    defaultValue=""
                    errorobj={errors}

                    {...props}
                    />
 
                   
               <FormInput 
                    name="website" 
                    label="Website" 
                    leftIcon = 'web'
                    control = {control}
                    rules = {{required: false, maxLength: 100,pattern:
                      {value:/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/i,message:'Not an valid URL'}
                    }}
                    defaultValue=""
                    errorobj={errors}
                    {...props}
                    />

            </View>

          <View style={{alignSelf:'flex-end',paddingTop:isSmallScreen ? 10 : 20}}>
          <input type="submit" value= {onboarding ? "submit" : "update"} id="profilesubmit"/>
          </View>

       </form>

    </Layout>
    </Card>

        <Modal
        visible={subscribeVisible}
        backdropStyle={{backgroundColor: 'rgba(0, 0, 0, 0.5)'}}
        onBackdropPress={() => setSubscribeVisible(false)}
        >
        <SelectPlan uid={uid} title="Subscribe now" message="Please select a plan to continue."/>
        </Modal>

          </KeyboardAwareScrollView>
        </Layout>
  );
}
