import React from 'react'
import {ActivityIndicator, StyleSheet, View } from 'react-native'

const ThemeActivityIndicator = (props) => {
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor: '#9FA8DA' // Set your own custom Color
  },
  horizontal: {
    flexDirection: "row",
    justifyContent: "space-around",
    padding: 10
  }
});

return (  
   <View style={[styles.container, styles.horizontal]}>
    <ActivityIndicator size="large" />
  </View>
) 
}

export default ThemeActivityIndicator;