import { StyleSheet } from 'react-native';

export default StyleSheet.create({
	overlay: {
	    position: 'absolute',
	    top: 0,
	    right: 0,
	    bottom: 0,
	    left: 0,
	    backgroundColor: 'red',
	    opacity: 0.3
	 },
  marker: {
     //Here is the trick
     position: 'absolute',
     width: 50,
     height: 50,
     alignItems: 'center',
     justifyContent: 'center',
     backgroundColor: '#ee6e73',                                    
     borderRadius: 30,            
     },
  container: 
    {
        flex: 1,
        backgroundColor: 'white',
        padding: 10,
    },
  columnContainer:{
    flex:1,
    flexDirection: 'column',
    justifyContent: 'center',
    margin: 10,
  },
  rowContainer: {
    flex:1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin:5,
  },
    infoWindow:{
	    position: 'absolute',
	    height: '50%',
	    width: '50%',
	    backgroundColor: '#fff',
	    opacity: 1,
	    justifyContent: 'center',
	    margin: 10,
	    flex:1
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  buttonContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 64,
  },
  content: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 4,
    paddingVertical: 2,
  },
  dayContainer: {
    flex: 1,
    justifyContent:'center',
    alignItems: 'center',
    aspectRatio: 1,
  },
  value: {
    fontSize: 12,
    fontWeight: '400',
  },
  text:{
    marginRight: 10,
    marginLeft: 10,
  },
  footerContainer: {
        flexDirection: 'row',
        justifyContent:'flex-end'
    },
});