import React from 'react'
import { Button, ThemeProvider } from 'react-native-elements';

const ThemeButton = (props) => {
const theme = {
  Button: {
    raised: true,
  },
};
   return (
    <ThemeProvider theme={theme}>
      <Button title= {props.title} {...props} />
    </ThemeProvider>
   )
}

export default ThemeButton;