import React, { useState } from 'react'
import { useWindowDimensions, View,Text } from 'react-native'
import { Layout,Button } from '@ui-kitten/components';


const OnboardingWelcome = ({navigation}) => {


  const dimensions = useWindowDimensions();
  const {height,width} = dimensions
  const isSmallScreen = width < 500

        return (
          <Layout style={{
          position: 'relative',
          height: isSmallScreen ? '90%' : '70%',
          width: isSmallScreen ? '90%' : '70%',
          backgroundColor: '#fff',
          justifyContent: 'center',
          alignItems:'center',
          alignSelf:'center',
          margin: 10,
          padding:10,
          flex:1
          }} 
         level='1' >
          { isSmallScreen ? 
          <>
          <Text style={{fontFamily: "Cochin",fontSize: 20,fontWeight: 400}} > Welcome to Foodpops!</Text>
          <Text style={{fontFamily: "Cochin",fontSize: 16,fontWeight: 400}} > Let's first setup up your truck profile. </Text>
          <Button style={{margin:50,alignSelf:'stretch'}} status='info' size='small' onPress={()=> navigation.navigate('setProfile')}>
          Start
          </Button>
          </>
          :
          <>
          <Text style={{fontFamily: "Cochin",fontSize: 40,fontWeight: 400}} > Welcome to Foodpops!</Text>
          <Text style={{fontFamily: "Cochin",fontSize: 30,fontWeight: 400}} > Let's first setup up your truck profile. </Text>
          <Button style={{margin:50,alignSelf:'stretch'}} status='info' size='giant' onPress={()=> navigation.navigate('setProfile')}>
          Start
          </Button>
          </>

          }
          </Layout>
        );
    }


export default OnboardingWelcome;
