import React, { useState } from 'react';
import styles from './styles';
import { Input,Card, Button, Text, Layout,Divider } from '@ui-kitten/components';

const SectionModal = ({submissionMode,section,onSubmit,onCancel}) => {

  const [value1, setValue1] = useState(section? section.title :'' );
  const [value2, setValue2] = useState(section? section.sectionTag: '' );

return (

                  <Card disabled={true}>
                    <Layout style={{ flex: 1, padding: 5 }} >

                    <Text style={styles.text} category='h1'> Menu category</Text>
                    <Divider/>

                    <Input 
                    style={{ paddingTop: 10 }}
                    value={value1}
                    label='Category name'
                    placeholder='eg. Entrees, Sandwiches, Tacos, Sides, Drinks'
                    onChangeText={nextValue => setValue1(nextValue)}
                    />
                     <Input
                    value={value2}
                    label='Unique category id (Optional)'
                    placeholder='eg. E, W, T, S, D '
                    caption='Items will be named after this letter for easy tracking. eg. E1, E2'
                    onChangeText={nextValue => setValue2(nextValue)}
                    style={{ paddingBottom: 10 }}
                  />

                  <Layout style={styles.footerContainer}>

                         <Button 
                         size='small'
                         status='basic'
                         onPress={onCancel}>
                            Cancel
                         </Button>

                        <Button 
                        size='small'
                         onPress={()=> onSubmit(submissionMode,value1,value2,section)}>
                            {submissionMode}
                        </Button>

      
                   </Layout>
                   </Layout>
                  </Card>

 )
}


export default SectionModal;
