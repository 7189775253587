import React, { useEffect, useState, lazy } from 'react';
import GoogleMapReact from 'google-map-react';
import { View, useWindowDimensions,TouchableOpacity } from 'react-native'
import { Ionicons } from '@expo/vector-icons'
import { firebase } from '../../firebase/config'
import CustomMarker from '../MapScreen/CustomMarker';
import * as geofire from 'geofire-common';
import {GOOGLEAPI_KEY} from '@env'
import MarkerClusterer from '@googlemaps/markerclustererplus'
import {ThemeActivityIndicator} from '../../styles';
import { Modal,Popover,Text,Card, Button} from '@ui-kitten/components';
import MenuList from './MenuList'
import "./styles.css";

const defaultCenter = {
    center: {
      lat:  41.850,
      lng:  -87.650
    },
    zoom: 11
  };


var clusterStyles = [
  {
    textColor: 'white',
    fontWeight:'bold',
    url: require('../../../assets/m1.png'),
    textSize:20,
    anchorText:[7,0],
    height: 38,
    width: 39
  },
 {
    textColor: 'white',
    fontWeight:'bold',
    url: require('../../../assets/m2.png'),
    textSize:20,
    anchorText:[8,0],
    height: 40,
    width: 41
  },
 {
    textColor: 'white',
    fontWeight:'bold',
    url: require('../../../assets/m3.png'),
    textSize:20,    
    anchorText:[11,0],
    height: 46,
    width: 47
  },
  {
    textColor: 'white',
    fontWeight:'bold',
    url: require('../../../assets/m4.png'),    
    textSize:20,   
    anchorText:[15,0],
    height: 55,
    width: 56
  },
  {
    textColor: 'white',
    fontWeight:'bold',
    url: require('../../../assets/m5.png'),
    textSize:20,    
    anchorText:[19,0],
    height: 62,
    width: 63
  }
];

var mcOptions = {
    styles: clusterStyles,
    maxZoom: 15
};

//  
export default function PreviewScreen(props)  {
  const uid = props.extraData
  const [location, setLocation] = useState(null);
  const [Markers, setMarkers] = useState([]);
  const [loading, setLoading] = useState(true)
  const [visible,setVisible] = useState(false)
  const locationRef = firebase.firestore().collection('users/'+uid+'/locations')
  const liveRef = firebase.firestore().collection('users/'+uid+'/live')
  const usersRef = firebase.firestore().collection('users').doc(uid+'/profile/default')

  var InforObj = [];
  const options = {year: 'numeric', month: '2-digit', day: '2-digit' }
  const todayString = new Date().toLocaleDateString("en-US",options)
  console.log(todayString)

  const dimensions = useWindowDimensions();
  const {height,width} = dimensions
  const isSmallScreen = width < 500

  useEffect(() => {
    retrieveData()
      if("geolocation" in navigator) {
        const geoWatch = navigator.geolocation.getCurrentPosition(
                    position => {
                      setLocation({lng:position.coords.longitude,lat:position.coords.latitude})
                    },
                  );
      } else {
        console.log('Your browser does not support user location')
      } 

    },[]);

const retrieveData = async () => {
      const sites = []
      const doc = await usersRef.get()
      const userData = doc.data()
      const profile = {
          email:userData.email,
          facebook:userData.facebook,
          instagram:userData.instagram,
          truck:userData.name,
          phone:userData.phone,
          twitter:userData.twitter,
          website:userData.website,
          uri: userData.uri
      }

      const snapshot = await locationRef.get()
      snapshot.forEach(async (loc) => {
        const location = loc.data()
        const timeSnapshot = await firebase.firestore().collection(`users/${uid}/locations/${location.id}/calendar`).where('date', '>=', todayString).orderBy('date', 'asc').get()

            let timeString = ''
            timeSnapshot.forEach((doc) => {
                const dict = doc.data()
                timeString += `\r\n${dict.date}:${dict.timeStart}-${dict.timeEnd}`
            }) 
            if (timeString!='') {
            sites.push({lng:location.geolocation.longitude,
             lat:location.geolocation.latitude,
              time:timeString,
              ...location, ...profile})
           }
        })


      const liveSnapshot = await liveRef.get()
        liveSnapshot.forEach(async (loc) => {
        const dict = loc.data()
        sites.push({lng:dict.geolocation.longitude,
         lat:dict.geolocation.latitude,
         name:'live',
         time:`${dict.datestring}:LIVE starting ${dict.timeStart}`,
        ...profile})
        })

      console.log('sites loaded',sites.length)
      setMarkers(sortMarkers(sites))
      setLoading(false)

}

const sortMarkers = (sites) => {
  let sorted = sites
  for (let j = 0; j < sorted.length; j++){
      sorted[j].time = String(sites[j].time).split("\r\n").sort().join("\r\n")
  }
  return sorted
}




const getInfoWindowString = (m) => {

  let contentString = `<div class='_map-infowindow' style="width:220px">
    <div style="font-size: 16px;font-weight:bold"">
   ${m.name}
    </div>`

    if (m.uri != '') {
      contentString+=`<img src=${m.uri} alt="profile" width="120" height="120">`
    }

    contentString+=`<div>
    <span style="font-size: 15px">
    ${m.truck}
    </span>
    `

    if (m.twitter != '') {
      contentString+=`<a href=${m.twitter} target="_blank">
        <img style="vertical-align: bottom" width="15" height="15" src="https://cdn3.iconfinder.com/data/icons/logotypes/32/twitter-24.png" alt="Twitter" class="twitter-icon"></a>
        `
    }

    if (m.facebook != '') {
      contentString+=`<a href=${m.facebook} target="_blank">
        <img style="vertical-align: bottom" width="15" height="15" src="https://cdn3.iconfinder.com/data/icons/logotypes/32/facebook-24.png" alt="Facebook" class="facebook-icon"></a>
        `
    }

    if (m.instagram != '') {
      contentString+=`<a href=${m.instagram} target="_blank">
        <img style="vertical-align: bottom" width="15" height="15" src="https://cdn3.iconfinder.com/data/icons/logotypes/32/instagram-24.png" alt="Instagram" class="instagram-icon"></a>
        `
    }

    if (m.website != '') {
      contentString+=`<a href=${m.website} target="_blank">
        <img style="vertical-align: bottom" width="15" height="15" src="https://cdn3.iconfinder.com/data/icons/social-media-outline-6/128/SocialMedia_Website-Outline-256.png" alt="website" class="website-icon"></a>
        `
    }


    contentString+=`<img style="vertical-align: bottom" width="15" height="15" onclick="showInfoWindow('${uid}')" src="https://cdn4.iconfinder.com/data/icons/online-marketing-hand-drawn-vol-4/52/web__globe__data__technology__list__menu__webpage-512.png" alt="menu" class="menu-icon">`
 
    if (m.phone != '') {
      contentString+=`<div>
      Tel:${m.phone}
      </div>`
    }

    if (m.email != '') {
      contentString+=`<div>
      Email:${m.email}
      </div>`
    }
      
    contentString+=`</div>
  
    <div style="color:blue" >
    Schedule
    </div>

    <div>
    ${m.time}
    </div>
</div>
` 

return contentString
}
 

window.showInfoWindow = function (uid) {
setVisible(true)
}

const renderMarkers = (map,maps) => {
  const customMarker = {
    path:
    "M16.3223077,0.0423076923 C20.3690605,0.0423076923 24.0709572,1.51881206 26.9186662,3.96248911 C26.076862,4.77779899 25.5538462,5.91974412 25.5538462,7.18384615 C25.5538462,9.66063084 27.5616769,11.6684615 30.0384615,11.6684615 C30.6692288,11.6684615 31.2695807,11.5382382 31.8140991,11.3032098 C32.3257878,12.8843575 32.6023077,14.5710402 32.6023077,16.3223077 C32.6023077,16.7454228 32.5861664,17.1647679 32.554466,17.5797608 C32.5444429,17.7227817 32.5311373,17.8734049 32.5125461,18.0380274 C32.3642834,19.456641 32.0338523,20.8198724 31.5453114,22.1052018 C31.4888354,22.2783322 31.4286736,22.4572898 31.3652794,22.6410508 C29.7909889,27.2044484 16.2741344,43.4840504 16.2741344,43.4840504 C7.21531308,32.9681794 2.20708836,26.1198326 1.24946021,22.9390097 C0.330137197,19.8854198 -0.0818742812,17.7566192 0.0134257788,16.5526079 L0.027980534,16.4069231 L0.042998968,16.4063077 L0.0423076923,16.3223077 C0.0423076923,7.43689074 7.16061952,0.214044594 16.0056873,0.0453250889 L16.3223077,0.0423076923 Z M16.2741344,10.1453846 C12.8159822,10.1453846 10.0125959,12.9487709 10.0125959,16.4069231 C10.0125959,19.8650753 12.8159822,22.6684615 16.2741344,22.6684615 C19.7322866,22.6684615 22.5356728,19.8650753 22.5356728,16.4069231 C22.5356728,12.9487709 19.7322866,10.1453846 16.2741344,10.1453846 Z",
    fillColor: "#D93F2A",
    fillOpacity: 1,
    strokeWeight: 2,
    strokeColor:"white",
    rotation: 0,
    anchor: maps.Point(17, 22),
  };

const liveMarker = {
    path:
    "M16.3223077,0.0423076923 C20.3690605,0.0423076923 24.0709572,1.51881206 26.9186662,3.96248911 C26.076862,4.77779899 25.5538462,5.91974412 25.5538462,7.18384615 C25.5538462,9.66063084 27.5616769,11.6684615 30.0384615,11.6684615 C30.6692288,11.6684615 31.2695807,11.5382382 31.8140991,11.3032098 C32.3257878,12.8843575 32.6023077,14.5710402 32.6023077,16.3223077 C32.6023077,16.7454228 32.5861664,17.1647679 32.554466,17.5797608 C32.5444429,17.7227817 32.5311373,17.8734049 32.5125461,18.0380274 C32.3642834,19.456641 32.0338523,20.8198724 31.5453114,22.1052018 C31.4888354,22.2783322 31.4286736,22.4572898 31.3652794,22.6410508 C29.7909889,27.2044484 16.2741344,43.4840504 16.2741344,43.4840504 C7.21531308,32.9681794 2.20708836,26.1198326 1.24946021,22.9390097 C0.330137197,19.8854198 -0.0818742812,17.7566192 0.0134257788,16.5526079 L0.027980534,16.4069231 L0.042998968,16.4063077 L0.0423076923,16.3223077 C0.0423076923,7.43689074 7.16061952,0.214044594 16.0056873,0.0453250889 L16.3223077,0.0423076923 Z M16.2741344,10.1453846 C12.8159822,10.1453846 10.0125959,12.9487709 10.0125959,16.4069231 C10.0125959,19.8650753 12.8159822,22.6684615 16.2741344,22.6684615 C19.7322866,22.6684615 22.5356728,19.8650753 22.5356728,16.4069231 C22.5356728,12.9487709 19.7322866,10.1453846 16.2741344,10.1453846 Z",
    fillColor: "#66D663",
    fillOpacity: 1,
    strokeWeight: 2,
    strokeColor:"white",
    rotation: 0,
    anchor: maps.Point(17, 22),
  };

    const markers = []
    Markers.map( (m,i) => {
       const marker = new maps.Marker({
        position: { lat: m.lat, lng: m.lng },
        map,
        title: m.name,
        icon: (m.name == 'live') ? liveMarker : customMarker,
        });

        const infowindow = new maps.InfoWindow({
          content: getInfoWindowString(m),

        });

        marker.addListener("click", () => {
        closeOtherInfo();
        infowindow.open(map, marker);
        InforObj[0] = infowindow;
        });

        markers.push(marker)        
    })

  const markerCluster = new MarkerClusterer(map, markers, mcOptions);

  return markers
}

function closeOtherInfo() {
      if (InforObj.length > 0) {
          /* detach the info-window from the marker ... undocumented in the API docs */
          InforObj[0].set("marker", null);
          /* and close it */
          InforObj[0].close();
          /* blank the array */
          InforObj.length = 0;
      }
  }

 const getMapOptions = (maps: any) => {
    return {
      disableDefaultUI: true,
      mapTypeControl: false,
      streetViewControl: false,
      zoomControl: false,
      mapTypeControl: false,
      };
  };



const menuAnchor = () => (
    <View style={{position: 'absolute',top:0,left:0.1*width,height:0,width:0}}>
    </View>
);


if (loading) {  
  return ( 
  <ThemeActivityIndicator /> 
  ) 
}


    return (

        <div style={{ height: isSmallScreen ? height-112 : height-64, width: isSmallScreen? width: width-150 }}>

          <GoogleMapReact
          bootstrapURLKeys={{ key: GOOGLEAPI_KEY}}
          defaultCenter={defaultCenter.center}
          defaultZoom={defaultCenter.zoom}
          options={getMapOptions}
          center={location}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({map, maps}) => {renderMarkers(map, maps)}}
          >
        </GoogleMapReact>
      

          <Modal
          visible={visible}
          backdropStyle={{backgroundColor: 'rgba(0, 0, 0, 0.5)'}}
          onBackdropPress={() => setVisible(false)}>
          <MenuList uid={uid}/>
        </Modal>
        
        <TouchableOpacity
        activeOpacity={0.7}
        onPress={()=>{setLoading(true);retrieveData();}}
        style={{
         position: 'absolute',
         width: isSmallScreen ? 30  : 50,
         height:  isSmallScreen ? 30  : 50,
         alignItems: 'center',
         justifyContent: 'center',
         right:  isSmallScreen ? 10  : 30,
         top:  10,
         backgroundColor: '#ee6e73',                                    
         borderRadius: 30}}>
        <Ionicons name='refresh-outline' color='#fff' size={isSmallScreen ? 15  : 25} />
        </TouchableOpacity>

      </div>
    
    );
}

