import React, { useState } from 'react'
import { ProfileScreen } from '../'
import styles from './styles';
import { Layout } from '@ui-kitten/components';
import { View,useWindowDimensions } from 'react-native'

const OnboardingProfile = ({navigation,extraData}) => {

  const dimensions = useWindowDimensions();
  const {height,width} = dimensions
  const isSmallScreen = width < 500

const handleOnSubmit = (e) => {
  if (e) {
      navigation.navigate('complete')
    } 
  }
        return ( 
          <Layout style={{
          position: 'relative',
          height: isSmallScreen ? '90%' : '70%',
          width: isSmallScreen ? '90%' : '70%',
          backgroundColor: '#fff',
          justifyContent: 'center',
          alignItems:'center',
          alignSelf:'center',
          margin: 10,
          padding:10,
          flex:1
          }} >

        <ProfileScreen onSubmitFinish={handleOnSubmit} extraData={{uid:extraData,onboarding:true}} />
        </Layout>

        );
    }

export default OnboardingProfile;