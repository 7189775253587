import React from 'react';
import Lottie from 'lottie-react-web'
import calendar from '../../assets/35674-calendar.json'
import livelocation from '../../assets/46896-location-received.json'
import menuNoodle from '../../assets/39520-japanese-noodles.json'
import completeCheck from '../../assets/3287-fireworks.json'

const CalendarAnimation = () => (
  <Lottie
    options={{
      animationData: calendar
    }}
  />
)

const LiveAnimation = () => (
  <Lottie
    options={{
      animationData: livelocation
    }}
  />
)


const MenuAnimation = () => (
  <Lottie
    options={{
      animationData: menuNoodle
    }}
  />
)

const CompleteAnimation = () => (
  <Lottie
    options={{
      animationData: completeCheck
    }}
  />
)



export {
   CalendarAnimation,
   LiveAnimation,
   MenuAnimation,
   CompleteAnimation
};