import 'react-native-gesture-handler';
import React, { useEffect, useState } from 'react'
import { NavigationContainer } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import { createDrawerNavigator } from '@react-navigation/drawer'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { SignUpScreen,MapScreen, MenuScreen, ProfileScreen, PreviewScreen } from './src/screens'
import { SelectPlan,OnboardingWelcome, OnboardingProfile, OnboardingTruck,OnboardingComplete } from './src/screens/OnboardingScreen';
import { ContactUs } from './src/components';

import { firebase } from './src/firebase/config';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import './src/styles/firebaseui-styling.global.css'

import '@stripe/stripe-js';
import { EvaIconsPack } from '@ui-kitten/eva-icons';
import { loadStripe } from '@stripe/stripe-js/pure';

import { decode, encode} from 'base-64'
import { Text,useWindowDimensions,Image, View,TouchableOpacity } from 'react-native'
import { ThemeActivityIndicator,ThemeButton} from './src/styles';
import styles from './styles';
import {MaterialIcons,Ionicons,MaterialCommunityIcons} from '@expo/vector-icons'

import * as eva from '@eva-design/eva';
import {Button,Modal,ApplicationProvider,IconRegistry,Drawer, DrawerItem, DrawerGroup, Layout, IndexPath,Divider} from '@ui-kitten/components';
import { default as theme } from './theme.json'; 
import { default as mapping } from './mapping.json'; 
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


if (!global.btoa) {  global.btoa = encode }
if (!global.atob) { global.atob = decode }

const Stack = createStackNavigator();

const { Navigator, Screen } = createDrawerNavigator();
const Tab = createBottomTabNavigator();

const MenuIcon = (props) => (
  <MaterialIcons name='restaurant-menu' size={15} />
);
const SettingIcon = (props) => (
  <Ionicons name='settings-sharp' size={15} />
);

const ProfileIcon = (props) => (
  <MaterialCommunityIcons name='account' size={15} />
);

const HomeIcon = (props) => (
  <Ionicons name='home-sharp' size={15} />
);

const MapIcon = (props) => (
  <MaterialCommunityIcons name='map-marker' size={15} />
);

const TruckIcon = (props) => (
  <MaterialCommunityIcons name='truck' size={15} />
);

const ContactIcon = (props) => (
  <MaterialIcons name='contact-support' size={15} />
);

const addUserRecord = (user) => {
    console.log('add user record')
    const usersRef = firebase.firestore().collection('users')
    const {uid,displayName,email,emailVerified,photoURL,phoneNumber,...rest} = user
      const data = {
      uid,displayName,email,emailVerified,photoURL,phoneNumber,
      providerId:user.providerData[0].providerId,
      lastSignIn:firebase.firestore.FieldValue.serverTimestamp(),
    }
    console.log(data,'added to db')
    usersRef
    .doc(uid)
    .set(data,{merge:true})
    .then(()=>{
      console.log('user data created',uid)
      }
    )
    .catch((error) => {
      console.log(error)
   });
}



export default function App() {
    const [user, setUser] = useState(null)
    const [subscribed, setSubscribed] = useState(null)
    const [loading, setLoading] = useState(true)
    const usersRef = firebase.firestore().collection('users');
    console.log(user,subscribed)

    // Listen to the Firebase Auth state and set the local state.
    useEffect(() => {
      const unregisterAuthObserver = firebase.auth().onAuthStateChanged(userAuth => {
          if (userAuth) {
          const uid = userAuth.uid
          console.log('AuthStateChange',userAuth)
          if (userAuth.providerData[0].providerId == 'password') {addUserRecord(userAuth)}
          setUser({uid:uid,operationType:'authChange'})
          } else {
          setLoading(false)
          }
          });                    
          
      return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    },[]);

  useEffect(() => {
    if (user) {
        console.log(user,'user exist, check subscription...')
        setLoading(true)
        const subscribeObserver = usersRef
        .doc(user.uid)
        .collection('subscriptions')
        .where('status', 'in', ['trialing', 'active','expired'])
        .onSnapshot(async (snapshot) => {
          setLoading(false)
          if (snapshot.empty) {
              setSubscribed('trialing')
          } else {
          let sub = null
          snapshot.docs.forEach((doc) => {
            if (doc.data()['status'] == 'active') {
              sub = 'active'
            } else if (doc.data()['status'] == 'trialing') {
              sub = (sub === 'active') ?  'active' : 'trialing'
            } else {
              sub = doc.data()['status']
            }
          });
          if (sub == 'expired') { sub = null }
          setSubscribed(sub)
          }
        });
        return () => subscribeObserver(); // Make sure we un-register Firebase observers when the component unmounts.
      }
  },[user]);


const signOut = () => {
    setUser(null)
    firebase.auth().signOut().then(() => {
      console.log('Sign out successful')
    // Sign-out successful.
    }).catch((error) => {
      console.log(error)
      // An error happened.
    });
}

if (loading) {  
  return ( 
  <ThemeActivityIndicator /> 
  ) 
}


const DrawerContent = ({ navigation, state }) => {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [subscribeVisible, setSubscribeVisible] = useState(false);
  const [contactUsVisible, setContactUsVisible] = useState(false);


const Header = (props) => (
  <React.Fragment>
  { (subscribed == 'trialing') && 
            <TouchableOpacity
            style={{backgroundColor:'#ee6e73', alignItems:'center', padding:5, borderRadius:0}}
            onPress={() => { setSubscribeVisible(true)}}
            >
            <Text style={{fontSize: 10,color:'#fff'}}> 
            You are currently 
            </Text>
            <Text style={{fontSize: 10,color:'#fff'}}> 
            on a 7-day trial. 
            </Text>
            <Text style={{fontSize: 10,color:'#fff'}}> click here to subscribe </Text>
            </TouchableOpacity>
  }

    <Divider/>
  </React.Fragment>
);

const Footer = (props) => (
  <React.Fragment>
  <Divider/>
  <Button size='tiny' appearance='ghost' status='info' accessoryLeft={ContactIcon} onPress={()=>setContactUsVisible(true)}>
  Contact Us 
  </Button>
  </React.Fragment>
);


return (
    <>
    { (subscribeVisible) && 
      <Modal
        visible={subscribeVisible}
        backdropStyle={styles.backdrop}
        onBackdropPress={() => setSubscribeVisible(false)}
        >
          <SelectPlan uid={user.uid} title="Subscribe now" message="Please select a plan to continue."/>
      </Modal>
    }

    { (contactUsVisible) && 
      <Modal
        visible={contactUsVisible}
        backdropStyle={styles.backdrop}
        onBackdropPress={() => setContactUsVisible(false)}
        >
        <ContactUs uid={user.uid} onPress={() => setContactUsVisible(false)} />
      </Modal>
    }
    <Drawer
      header={Header}
      footer={Footer}
      selectedIndex={selectedIndex}
      onSelect={index => navigation.navigate(state.routeNames[index.section*2+index.row])}>
      <DrawerGroup title='Home' accessoryLeft={HomeIcon}>
        <DrawerItem title='Schedule' accessoryLeft={TruckIcon}/>
        <DrawerItem title='Preview' accessoryLeft={MapIcon}/>
      </DrawerGroup>
      <DrawerGroup title='Setting' accessoryLeft={SettingIcon}>
       <DrawerItem title='Profile' accessoryLeft={ProfileIcon}/>
        <DrawerItem title='Menu' accessoryLeft={MenuIcon}/>
      </DrawerGroup>
</Drawer>


</>
  );
}


const HomeBottomTabs = (props) => {
   return (

       <Tab.Navigator
        screenOptions={({ route }) => ({
          tabBarIcon: ({ focused, color, size }) => {
         if (route.name === 'Schedule') {
            return <MaterialCommunityIcons name='truck' color={color} size={20} /> 
          } else if (route.name === 'Preview') {
               return <MaterialCommunityIcons name='map-marker' color={color} size={20} /> 
          }  else if (route.name === 'Profile') {
             return <MaterialCommunityIcons name='account' color={color} size={20} /> 
          } else if (route.name === 'Menu') {
                 return <MaterialIcons name='restaurant-menu' color={color} size={20} /> 
          }
            },
          })}
          tabBarOptions={{
          activeTintColor: 'tomato',
          inactiveTintColor: 'gray',
          }}
    >
        <Tab.Screen name="Schedule">
        {props => <MapScreen {...props} extraData={user.uid} />}
        </Tab.Screen>
        <Tab.Screen name="Preview">
        {props => <PreviewScreen {...props} extraData={user.uid} />}
        </Tab.Screen>
        <Tab.Screen name="Profile">
        {props => <ProfileScreen {...props} extraData={{uid:user.uid,subscribed:subscribed}}/>}
        </Tab.Screen> 
        <Tab.Screen name="Menu">
        {props => <MenuScreen {...props} extraData={user.uid} />}
        </Tab.Screen> 
    </Tab.Navigator>
  )
}

const HomeDrawers = (props) => {

    return (
    <Navigator drawerContent={props => <DrawerContent {...props}/>} 
    openByDefault={true} 
    headerMode='float'
    drawerStyle={{ width: 150 }}
    overlayColor="transparent"
    drawerType='permanent'
    >
        <Screen name="Schedule">
        {props => <MapScreen {...props} extraData={user.uid} />}
        </Screen>
        <Screen name="Preview">
        {props => <PreviewScreen {...props} extraData={user.uid} />}
        </Screen>
        <Screen name="Profile">
        {props => <ProfileScreen {...props} extraData={{uid:user.uid,subscribed:subscribed}}/>}
        </Screen> 
        <Screen name="Menu">
        {props => <MenuScreen {...props} extraData={user.uid} />}
        </Screen> 
    </Navigator>
  );
}

const SignUpStack = () => {
 

const SignInScreen = () => {

    const TOSURL = 'https://info970307.wixsite.com/foodpops/terms-of-service'
    const PPURL = 'https://info970307.wixsite.com/foodpops/privacy'

    // Configure FirebaseUI.
    const uiConfig = {
      signInFlow: firebase.auth().isSignInWithEmailLink(window.location.href) ? 'redirect' :'popup',
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        firebase.auth.TwitterAuthProvider.PROVIDER_ID,
        // firebase.auth.EmailAuthProvider.PROVIDER_ID,
        {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
      forceSameDevice: false,
      emailLinkSignIn: function() {
        return {
          handleCodeInApp: true,
        };
      }
    }

      ],
      // Terms of service url/callback.
      tosUrl: '{TOSURL}',
      // Privacy policy url/callback.
      privacyPolicyUrl: function() {
        window.location.assign('{PPURL}');
      },

      callbacks: {
      signInSuccessWithAuthResult: function(authResult, redirectUrl) {
        console.log(authResult)
        var user = authResult.user;
        console.log('sign In successful')
        addUserRecord(user)
        setUser({uid:uid,operationType:operationType,isNewUser:isNewUser})
        setLoading(false)
        return false;
      },

      signInFailure: function(error) {
        alert("User does not exist in db.")
        return handleUIError(error);
      },
          credentialHelper: 'none'

    },
    }

    return (
          <View style={{flex: 1, alignItems: 'center'}}>
          <Image
            source={{uri:require('./assets/foodpops.png')}}
            style= {{
                height: 76,
                width: 76,
                alignSelf: "center",
                margin: 30,
                borderRadius: 10
            }}  
          />
          <StyledFirebaseAuth styles={{ flex:1, minHeight: '100vh' }} uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
          </View>

      )
  }


const dimensions = useWindowDimensions();
const {height,width} = dimensions


const navigationOptions = () => {
    return {
        headerStyle: {
        backgroundColor: '#13616C'
        },
        headerTintColor: '#fff',
        headerTitleStyle: {
        alignSelf: 'center',
        fontWeight: 'bold',
        fontSize:0.04*width,
        },
        headerTitle:'Welcome to FoodPops for Food Truck Owners!',
   }
 }

  return (
    <Stack.Navigator screenOptions={navigationOptions}>
          <Stack.Screen name="FoodPops" component={SignUpScreen} />
          <Stack.Screen name="SignIn"  component={SignInScreen} />
    </Stack.Navigator>

    )
}


const RootStack = (props) => {
    const [rootStackLoading, setRootStackLoading] = useState(true)
    const [onboarding, setOnboarding] = useState(false)
    const uid = props.extraData
    const ref = firebase.firestore().doc("users/" + uid + '/setting/config')
    console.log(uid,onboarding)
    const dimensions = useWindowDimensions();
    const isSmallScreen = dimensions.width < 500
    const [drawerStatus,setDrawerStatus] = useState(true)

    useEffect(() => {
      console.log('useEffect onboarding',onboarding)
      ref
      .get()
      .then(function(doc) {
        if (doc.data() && doc.data()['onboarding'] && doc.data()['onboarding']==true) {
          console.log('useEffect onboarding',onboarding)
          setOnboarding(true)
          setRootStackLoading(false)
        } else {
          setRootStackLoading(false)
        }
      }, err => {
      console.log(err) 
      setRootStackLoading(false)

    });
    }, [onboarding])

    if (rootStackLoading) {  
    return ( 
        <ThemeActivityIndicator /> 
        )
    }

const onboardingComplete = (e) => {
      if (e) {
      setOnboarding(true)
      console.log('onboarding complete')
      ref
      .set({'onboarding':true})
      .then()
      .catch((error) => {
      console.log(error)
        });
     }
   }


return (
        <Stack.Navigator screenOptions={{ 
            headerStyle: {
            backgroundColor: '#67E3C3',
            },
            headerTintColor: '#fff',
            headerTitleStyle: {
            alignSelf: 'center',
            fontWeight: 'bold',
            fontSize: isSmallScreen ? 14 : 32,
            },
            headerTitle:'FoodPops for Food Truck Owners',
            headerRight: () => (
            <ThemeButton
              onPress={signOut}
              title="Sign out"
              type='clear'
              containerStyle={{paddingRight:5}}
              titleStyle={{color:'#fff',fontSize: isSmallScreen ? 12 : 18}}
            />
            ),
            }}
         >
        { onboarding ? 
          ( isSmallScreen ? 
         <Stack.Screen name="FoodPops" component={HomeBottomTabs}/>
         :
         <Stack.Screen name="FoodPops" component={HomeDrawers}/>
          )
           : 
           <>
            <Stack.Screen name="Welcome" component={OnboardingWelcome} />
            <Stack.Screen name="setProfile">
            {props => <OnboardingProfile {...props} extraData={user.uid}/>}
           </Stack.Screen>
            <Stack.Screen name="complete">
            {props => <OnboardingComplete {...props} complete ={onboardingComplete}/>}
           </Stack.Screen>
           </>
          
        }
        </Stack.Navigator> 
        )
}


return (
  <>
    <IconRegistry icons={EvaIconsPack} />
      <ApplicationProvider {...eva} customMapping={mapping} theme={{ ...eva.light, ...theme }}>
      <NavigationContainer>
        <Stack.Navigator headerMode='none'>

            { user ? ( subscribed ? 
              (
               <Stack.Screen name="Root">
               {props => <RootStack {...props} extraData={user.uid}/>}
               </Stack.Screen>
              ) : (<Stack.Screen name="Subscribe">
                  {props => <SelectPlan uid={user.uid} title="Your trial is expired." message="Please select a plan to continue."/>}
              </Stack.Screen>)
              ):(
            <Stack.Screen name="Sign up" component={SignUpStack} />
            )
          }
        </Stack.Navigator>
        </NavigationContainer>
      </ApplicationProvider>
      <ToastContainer position="top-center" autoClose="2000"/>
   </>
  );
}
