import React, { useState } from 'react'
import { Button, useWindowDimensions,Text,View, StyleSheet,TouchableOpacity } from 'react-native'
import {ThemeActivityIndicator} from '../../styles';
import styles from './styles';
import '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { firebase } from '../../firebase/config'
import {STRIPE_KEY,STRIPE_MPLAN,STRIPE_YPLAN} from '@env'
import { Card } from '@ui-kitten/components';

const SelectPlan = (props) => {
  const {uid,title,message} = props
  const [loading, setLoading] = useState(false)
  const dimensions = useWindowDimensions();
  const {height,width} = dimensions
  const isSmallScreen = width < 500

  const subscribe = async (uid,plan) => {
          console.log(uid)

          setLoading(true)
          const stripe = await loadStripe(STRIPE_KEY);
          const docRef = await firebase.firestore()
          .collection('users')
          .doc(uid)
          .collection('checkout_sessions')
          .add({
            price: plan,
            allow_promotion_codes: true,
            success_url: window.location.origin,
            cancel_url: window.location.origin,
            trial_from_plan:true
          });

          // Wait for the CheckoutSession to get attached by the extension
          docRef.onSnapshot(async (snap) => {
            const { error, sessionId } = snap.data();
            if (error) {
              alert(`An error occured: ${error.message}`);
              setLoading(false)
            }
            if (sessionId) {
              await stripe.redirectToCheckout({ sessionId });
              setLoading(false)
            }
          });
      }


if (loading) {  
  return ( 
  <ThemeActivityIndicator /> 
  ) 
}
        return (
        <Card style={{
          flex:1,
          position: 'relative',
          justifyContent: 'center',
          alignSelf:'center',
          backgroundColor: '#fff',
          alignItems:'center',
          margin:20,
          padding:isSmallScreen ? 20 : 50,
          }} >

            {isSmallScreen ? 
              <>
             <Text style={{alignSelf:'center',fontFamily: "Cochin",fontSize: 20,fontWeight: 400,paddingButtom:40}}>
             {title}
             </Text>
             <Text style={{alignSelf:'center',fontFamily: "Cochin",fontSize: 16,fontWeight: 400,paddingButtom:40}}>
             {message}
             </Text>
              </>
              :
               <>
             <Text style={{alignSelf:'center',fontFamily: "Cochin",fontSize: 30,fontWeight: 400,paddingButtom:40}}>
             {title}
             </Text>
             <Text style={{alignSelf:'center',fontFamily: "Cochin",fontSize: 24,fontWeight: 400,paddingButtom:40}}>
             {message}
             </Text>
              </>
              }
             <View style={{paddingTop:40,flexDirection: 'row', flexWrap:'wrap',justifyContent: 'space-between'}}>

                  <TouchableOpacity
                      activeOpacity={0.8}
                      onPress={()=> subscribe(uid,STRIPE_MPLAN)}
                      style={styles.button}>

                      <Text style={{color:'#fff',fontFamily: "Cochin",fontSize: 20,fontWeight: 800}}>Monthly plan</Text>

                      <View style={{flexDirection: 'row',alignItems:'flex-end',padding:5}} >
                          <Text style={styles.text}>
                          $9.99
                          </Text>
                          <Text style={styles.text}>
                          /month
                        </Text>
                      </View>
                  </TouchableOpacity>

                  <TouchableOpacity
                          activeOpacity={0.8}
                          onPress={()=> subscribe(uid,STRIPE_YPLAN)}
                          style={styles.button}>
                        
                        <Text style={{color:'#fff',fontFamily: "Cochin",fontSize: 20,fontWeight: 800}}>Yearly plan</Text>

                        <View style={{flexDirection: 'row',alignItems:'flex-end',padding:5}} >
                        <Text style={styles.text}>
                        $99.99
                        </Text>
                        <Text style={styles.text}>
                        /year
                        </Text>
                        </View>

                        <Text style={styles.text}>
                        with two month free             
                        </Text>

                  </TouchableOpacity>
            </View>
          </Card>

        );
    }


export default SelectPlan;
