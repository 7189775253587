import React, {useEffect, useState, useRef } from 'react';
import GoogleMapReact from 'google-map-react';
import {GOOGLEAPI_KEY} from '@env'
import { View, useWindowDimensions,TouchableOpacity } from 'react-native'
import { MaterialIcons } from '@expo/vector-icons'
import styles from './styles';
import Marker from './Marker';
import SetLive from './SetLive';
import './styles.css';
import { firebase } from '../../firebase/config'
import { Modal,Popover,Card,Layout,Button } from '@ui-kitten/components';
import CalendarSelect from './Calendar'
import PopoverMarker from './PopoverMarker';
import SearchBox from './SearchBox';
import CustomMarker from './CustomMarker';
import * as geofire from 'geofire-common';
import { toast } from 'react-toastify';

const customIcon = {
    path: "M8.16115385,0.0211538462 C10.1848865,0.0211538462 12.0361303,0.759666 13.4600851,1.98188993 C13.0383666,2.38904002 12.7769231,2.95994984 12.7769231,3.59192308 C12.7769231,4.83031542 13.7808384,5.83423077 15.0192308,5.83423077 C15.3339494,5.83423077 15.6335245,5.76939337 15.9053271,5.65234751 C16.1628385,6.44184099 16.3011538,7.28534478 16.3011538,8.16115385 C16.3011538,8.36707466 16.2935075,8.57120957 16.2784834,8.77329016 C16.2727038,8.85990564 16.2637749,8.95968016 16.2500799,9.07165096 C16.1743963,9.75690504 16.0134996,10.4149791 15.778659,11.0367564 C15.7481386,11.1280886 15.7163005,11.2229527 15.6826397,11.3205254 C14.8954944,13.6022242 8.13706719,21.7420252 8.13706719,21.7420252 C3.60765654,16.4840897 1.10354418,13.0599163 0.624730107,11.4695049 C0.175841915,9.97849416 -0.0311412843,8.92845736 0.00378050851,8.31939447 L0.013990267,8.20346154 L0.021999484,8.20315385 L0.0211538462,8.16115385 C0.0211538462,3.7488078 3.53182792,0.156423608 7.91229186,0.0248854924 L8.16115385,0.0211538462 Z M8.13706719,5.07269231 C6.40799109,5.07269231 5.00629796,6.47438544 5.00629796,8.20346154 C5.00629796,9.93253764 6.40799109,11.3342308 8.13706719,11.3342308 C9.86614329,11.3342308 11.2678364,9.93253764 11.2678364,8.20346154 C11.2678364,6.47438544 9.86614329,5.07269231 8.13706719,5.07269231 Z",
    fillColor: '#FF3B30',
    fillOpacity: 1,
    strokeColor: 'white',
    strokeWeight: 1,
    scale: 2,
};

const greenIcon = {
    path: "M7.8,1.3L7.8,1.3C6-0.4,3.1-0.4,1.3,1.3c-1.8,1.7-1.8,4.6-0.1,6.3c0,0,0,0,0.1,0.1 l3.2,3.2l3.2-3.2C9.6,6,9.6,3.2,7.8,1.3C7.9,1.4,7.9,1.4,7.8,1.3z M4.6,5.8c-0.7,0-1.3-0.6-1.3-1.4c0-0.7,0.6-1.3,1.4-1.3 c0.7,0,1.3,0.6,1.3,1.3 C5.9,5.3,5.3,5.9,4.6,5.8z",
    fillColor: 'green',
    fillOpacity: 1,
    strokeColor: 'white',
    strokeWeight: 1,
    scale: 3.5,
};

const defaultCenter = {
    center: {
      lat:  41.850,
      lng:  -87.650
    },
    zoom: 11
  };

const options = {year: 'numeric', month: '2-digit', day: '2-digit' }

export default function MapScreen(props)  {
  const {navigation,extraData} = props
  const uid = extraData
  const [location, setLocation] = useState(null);
  const [placeMarker, setPlaceMarker] = useState(null);
  const ref = firebase.firestore().collection('users/'+uid+'/locations')
  const collectionRef = firebase.firestore().collection('users/'+uid+'/live/')
  const [anchor,setAnchor] = useState(null);
  const [map,setMap] = useState(null);
  const [maps,setMaps] = useState(null);
  const [apiReady,setApiReady] = useState(false);
  const [Markers, setMarkers] = useState([]);
  const [liveMarkers, setLiveMarkers] = useState([]);
  const [locationVisible, setLocationVisible] = useState(true);
  const tag = new URL(window.location.href).searchParams.get('nfc') ? new URL(window.location.href).searchParams.get('nfc') : 'web'
  const [visible,setVisible] = useState(tag == 'web'? false : true)
  const [selectedMarker, setSelectedMarker] = useState(null);

  const dimensions = useWindowDimensions();
  const {height,width} = dimensions
  const isSmallScreen = width < 500


useEffect(() => {
    if (placeMarker && Markers.filter(x=> x.id == placeMarker.id).length>0) {
      setPlaceMarker(null)
    } 
    },[Markers]);



useEffect(() => {

    
    getCurrentLocation()
    getLiveMarkers()

    const observer = ref.onSnapshot(querySnapshot => {
          const sites = []
          querySnapshot.forEach((doc) => {
            console.log('successfully received the data',doc.data())
            const dict = doc.data()
            sites.push({lng:dict.geolocation.longitude,lat:dict.geolocation.latitude,name:dict.name,id:dict.id})
             });
          setMarkers(sites)
          // console.log(sites)
      }, err => {
        // console.log(`Encountered error: ${err}`);
      });

      return () => observer(); // Make sure we un-register Firebase observers when the component unmounts.
      
    },[]);


 const getCurrentLocation = () => {
  // current
    if("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
                  (position)=> {
                    console.log(position.coords)
                    setLocation({lng:position.coords.longitude,lat:position.coords.latitude,name:'current',status:'close',id:tag})
                    if (tag!="web") { 
                      setSelectedMarker({lng:position.coords.longitude,lat:position.coords.latitude,name:'current',status:'close',id:tag})
                    }
                  },
                  (error) => {
                        toast.error("Oops,we can't find your current location quick enough! Please try again later!");
                  },
                  {maximumAge: 0,enableHighAccuracy: true,timeout: 10000}
                );
    } else {
      console.log('Your browser does not support user location')
    }

 }
 const getMapOptions = (maps: any) => {
    return {
      disableDefaultUI: true,
      streetViewControl: true,
      zoomControl: true,
      mapTypeControl: true,
      mapTypeControlOptions: {
            style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: maps.ControlPosition.LEFT_BOTTOM,
            mapTypeIds: [
                maps.MapTypeId.ROADMAP,
                maps.MapTypeId.SATELLITE            ]
        },
      styles: [{ featureType: 'poi', elementType: 'labels', stylers: [{ visibility: 'on' }] }],
    };
  };

const renderMarkers = (map, maps) => {
    setMap(map)
    setMaps(maps)
    setApiReady(true)
}


const getLiveMarkers = async () => {


   let today = new Date()
    let currentTime = today.getTime() 

    const snapshot = await collectionRef.where('status', '==', 'open').get();
    if (snapshot.empty) {
      console.log('No matching documents.');
      return;
    }  
    const sites = []    

    snapshot.forEach(doc => {
      const dict = doc.data()

      if (dict.id == tag) {
        setLocationVisible(false)
      } 

      const site = {
        lng:dict.geolocation.longitude,
        lat:dict.geolocation.latitude,
        status:dict.timeStart > currentTime ? 'delayOpen' : 'open',
        id:dict.id
      }
      sites.push(site)

    });

    setLiveMarkers(sites)
}



const updateCurrentStatusWithSelection = (timeInAdvance) => {
  setVisible(!visible);

  if (location) {
     let today = new Date()
     let newTime = new Date(today.getTime() + timeInAdvance*60000)

     location.status = timeInAdvance > 0 ? 'delayOpen' : 'open'
     const docRef = collectionRef.doc(location.id)
     const data = {
              datestring:today.toLocaleDateString("en-US",options),
              geolocation: new firebase.firestore.GeoPoint(location.lat, location.lng),
              geohash:geofire.geohashForLocation([location.lat, location.lng]),
              status: 'open',
              timeStart:newTime.toLocaleTimeString(),
              lastupdated:firebase.firestore.FieldValue.serverTimestamp(),
              id:location.id
      }

      docRef
        .set(data)
        .then(
          console.log('successfully update the database on live update')
        )
        .catch((error) => {
            console.log(error)
        });

  } else {

  alert('trouble getting current location')

  }
}


const closeSelectedMarker = () => {
  setVisible(!visible);
  if (selectedMarker.id == tag) {
    location.status = 'close'
    setLocationVisible(true)
  } 
  setLiveMarkers(liveMarkers.filter(x=> x.id != selectedMarker.id))
  
  collectionRef.doc(selectedMarker.id)
        .delete()
        .then(
          console.log('successfully delete the database on live update')
        )
        .catch((error) => {
            console.log(error)
        });
}

const handleMarkerClicked = (props) => {
    setSelectedMarker(props)
    setVisible(!visible);
}


const searchBox = () => (

    <SearchBox
     map={map}
     mapsapi={maps}
     onPlacesChanged={handlePlaceChanged}
     style={{
      position: 'absolute',margin:20,top:0,left:0}}
     />

);

const CurrentLocationButton = () => (

    <TouchableOpacity
    activeOpacity={0.7}
    onPress={()=>{getCurrentLocation()}}
    style={{
     position: 'absolute',
     width: isSmallScreen ? 30  : 50,
     height:  isSmallScreen ? 30  : 50,
     alignItems: 'center',
     justifyContent: 'center',
     left:  isSmallScreen ? 10  : 30,
     top:  60,
     backgroundColor: '#ee6e73',                                    
     borderRadius: 30}}>
    <MaterialIcons name='my-location' color="#fff" size={isSmallScreen ? 15  : 25} />
    </TouchableOpacity>

);


const handlePlaceChanged = (result) => {
  if (result) {
      const location = result.geometry.location
      map.setCenter(location);
      const docRef = ref.doc()
      const newMarker = {lat:location.lat(), lng:location.lng(),name:result.name,id:docRef.id}
      setPlaceMarker(newMarker)
  }

}


  


  const divRef = useRef();

    return (
        <div style={{ height: isSmallScreen ? height-112 : height-64, width: isSmallScreen? width: width-150 }}>
          <GoogleMapReact
          bootstrapURLKeys={{ key: GOOGLEAPI_KEY,libraries: ['places'] }}
          defaultCenter={defaultCenter.center}
          defaultZoom={defaultCenter.zoom}
          options={getMapOptions}
          center={location}
          loaded={({map,maps}) => console.log(map,maps)}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({map, maps}) => renderMarkers(map, maps)}
          >

          {Markers && Markers.map((m,i) => 
            <CustomMarker
            key={i}
            onClick={()=>setAnchor(m)}
            {...m}
            />)
          }

            {placeMarker && <Marker
            onClick={()=> setAnchor(placeMarker)}
            {...placeMarker}
            />}

          {liveMarkers && liveMarkers.map((m,i) => 
            <Marker
            key={i}
            color={m.status == 'delayOpen' ? 'yellow' : 'red'}
            onClick={handleMarkerClicked}
            {...m}
            />)
          }

           { location && locationVisible && <Marker
            color={location.status == 'delayOpen' ? 'yellow' : (location.status == 'open' ? 'red' : 'blue')}
            onClick={handleMarkerClicked}
            {...location}
            />}


        </GoogleMapReact>


         {
          visible && selectedMarker && (<Modal
          visible={visible}
          backdropStyle={styles.backdrop}
          onBackdropPress={() => setVisible(false)}>

          <View style={{flex: 1,alignItems: 'center'}}>
          {
            (selectedMarker.id == tag && locationVisible && location.status == 'close') ? (
            <SetLive {...props} selection={updateCurrentStatusWithSelection} canceled={()=> setVisible(false)} />
            ) : (
              <Card status='basic'>
                  <Layout style={{padding:10,margin:10}}>
                      <Button 
                      size='median' 
                      status='danger'
                      onPress={closeSelectedMarker}>
                        Cancel
                      </Button>
                  </Layout>  
              </Card>
            ) 
          }
          </View>
          </Modal>)
         }


          {apiReady && (
            <SearchBox
                 map={map}
                 mapsapi={maps}
                 onPlacesChanged={handlePlaceChanged}
                 style={{
                  position: 'absolute',margin:10,top:0,left:0,width:300,height:40
                }}
             />
            )}

          {isSmallScreen ? 
            (
            <>
            <CurrentLocationButton/>
            <Modal
            visible={anchor}
            backdropStyle={styles.backdrop}
            onBackdropPress={() => setAnchor(null)}>
            <View style={styles.content}>
              <CalendarSelect onSubmit={() => setAnchor(null)} marker={anchor} uid={uid}/>
            </View>
          </Modal>
            </>
            )
          : 
           <Popover
            anchor={CurrentLocationButton}
            visible={anchor}
            placement='right start'
            onBackdropPress={() => setAnchor(null)}>
            <View style={styles.content}>
              <CalendarSelect onSubmit={() => setAnchor(null)} marker={anchor} uid={uid}/>
            </View>
          </Popover>
           }
      </div>
    );
}

            


