import React, {useState} from 'react';
import { View } from 'react-native'
import {Picker} from '@react-native-picker/picker';
import styles from './styles';
import { Text,Card,Layout,Button } from '@ui-kitten/components';

const list = [
  {
    time: 'NOW',
    value:0
  },
  {
    time: 'in 30 mins',
    value:30

  },
  {
    time: 'in 1 hour',
    value:60
  },
  {
    time: 'in 1 hour 30 mins',
    value:90

  },
  {
    time: 'in 2 hours',
    value:120
  },
]


const SetLive = (props) => {

  const handleSelection = (item) => {
  	props.selection(selectedValue)
  }

  const handleCancel = () => {
   props.canceled(true) 
  } 



const [selectedValue, setSelectedValue] = useState(0);


  return (

    <Card status='basic'>

  	<Layout style={{padding:10,margin:10,flex:1,flexDirection: 'row',alignItems:'center'}} level="1">
  	  	<Text status='danger' category='h4'> Open </Text>


              <Picker
                selectedValue={selectedValue}
                onValueChange={(itemValue) => setSelectedValue(itemValue)}
                itemStyle={{ fontFamily:"OpenSans-Regular", fontSize:17}}
                style={{padding:5}}
               >
                {
                list.map(
                  (c,i) =>
                  <Picker.Item key={i} label={c.time} value={c.value} />
                  )
                }    
              </Picker>

              <Button 
              size='small' status='info'
               onPress={handleSelection}>
                Confirm
              </Button>
      </Layout>

    </Card>


 )

}

export default SetLive;
