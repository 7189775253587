import React from "react";
import {useForm, Controller } from "react-hook-form";
import {Input} from 'react-native-elements';
import { MaterialCommunityIcons } from '@expo/vector-icons'

function FormInput(props) {
  const { defaultValue, control, name, required,label, rules,errorobj, leftIcon,ref} = props;
  let isError = false;
  let errorMessage = "";
  if (errorobj && errorobj.hasOwnProperty(name)) {
    isError = true;

    if (errorobj[name].type === "maxLength") {
    	errorMessage = "Your input exceeds maximum length"
    } else {
    	errorMessage = errorobj[name].message;
    }

  }
  const input = React.createRef();

  return (
	    <Controller
	      name={name}
	      control={control}
          render={(props) => (
		    <Input
		      {...props}
		      ref={input}
		      label={required ? label+'*' : label}
		      name={name}
		      labelStyle={required ? {'color': 'red'} : null}
		      leftIcon={ leftIcon && <MaterialCommunityIcons name={leftIcon} size={20} />}
		      errorStyle={{ color: 'red' }}
  			  errorMessage={errorMessage}
		    />
		  )}
		  rules={rules}
	      fullWidth={true}
	      error={isError}
	      helperText={errorMessage}
	      defaultValue={defaultValue}
	    />
  );
 }

export default FormInput;
