import React, { useEffect, useState } from 'react';
import {useWindowDimensions, SafeAreaView, SectionList, Image, View, FlatList} from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { createStackNavigator } from '@react-navigation/stack'
import MenuItemScreen from './MenuItem'
import SectionModal from './SectionModal'
import { firebase } from '../../firebase/config'
import styles from './styles';
import { v4 as uuidv4 } from 'uuid';
import {Divider,Modal,Card, Button, Text, Layout,TopNavigation, TopNavigationAction } from '@ui-kitten/components';
import {MaterialIcons} from '@expo/vector-icons'
import {ThemeActivityIndicator} from '../../styles';

const MenuStack = createStackNavigator();

const MenuScreen = (props) => {
  const {navigation} = props
  const uid = props.route.params.uid
  const menuRef = firebase.firestore().collection('users/'+uid+'/menu')
  const [menuItems, setMenuItems ] = useState([]);
  const [visible, setVisible] = useState(false);
  const [URLs,setURLs] = useState([]);
  const [loading, setLoading] = useState(true)
  const [selectedSection, setSelectedSection] = useState([]);
  const [sectionMode, setSectionMode] = useState('');
  const storage = firebase.storage().ref();
  const [alertVisible, setAlertVisible] = useState(false);
  const [selectedDeleteSection, setSelectedDeleteSection] = useState(null);

  const dimensions = useWindowDimensions();
  const {height,width} = dimensions
  const isSmallScreen = width < 500
  const initCol = isSmallScreen ? Math.floor((width-30)/200) : Math.floor((width-210)/200)
  const [nCol,setCols] = useState(initCol ? initCol : 1)  
  const cardWidth = (isSmallScreen && nCol==1) ? width-30 : 180

  useEffect(() => {
    console.log('useEffect called')

    const retrieveItemsFromDB = async () => {
      const dbmenuItems = []
      let allSections = await menuRef.get();

      for(const doc of allSections.docs) {
          const dbsection = doc.data()
          let data = []
          let allItems = await menuRef.doc(doc.id).collection("data").get()

          for (const item of allItems.docs) {
            let itemData = item.data()
            // loading raw image source
            if ("imagePath" in itemData) {
              const ref = storage.child(itemData.imagePath);
              const url = await ref.getDownloadURL()
              itemData['uri'] = url
            }
            data.push(itemData)
          }
          data.sort((a, b) => a.index - b.index)
          dbsection.data = [{id:dbsection.id,list:data}]
          dbmenuItems.push(dbsection)
          }

        dbmenuItems.sort((a, b) => a.index - b.index);
        setMenuItems(dbmenuItems)
        setLoading(false)
        console.log(dbmenuItems)
    }

    try {
        retrieveItemsFromDB();
    } catch (e) {
        console.error(e);
        setLoading(false)
    } 
 

 },[])


  const updateItem = (item) => {

    if (item) {
          const sectionIndex = menuItems.map(function(x) {return x.id; }).indexOf(item.sectionID);
          const items = [...menuItems]

        if (item.index == items[sectionIndex].data[0].list.length) {
           console.log('add new')
           let newList = items[sectionIndex].data[0].list
           newList.push(item.data)
           items[sectionIndex].data = [{id:item.sectionID,list:newList}] 
        } else {
           console.log('update')
           items[sectionIndex].data[0].list[item.index] = item.data
        }
         console.log(items)
         setMenuItems(items)
    }
  }

  
const deleteSection = () => {
  const items = menuItems.filter(x => x.id !== selectedDeleteSection.id);
  setMenuItems(items)
  deleteFromDB(selectedDeleteSection.id)
}


const handleSectionDelete = (section) => {
   setSelectedDeleteSection(section)
   setAlertVisible(true)
}

const deleteItemById = (item,section) => {
  const newList = section.data[0].list.filter(x => x.id !== item.id)
  const newSection = {...section,data:[{id:section.id,list:newList}]}
  console.log(newSection)

  let items = [...menuItems]
  let sectionIndex = menuItems.map(function(x) {return x.id; }).indexOf(section.id);
  items[sectionIndex] = newSection
  setMenuItems(items)
  deleteFromDB(`${section.id}/data/${item.id}`)
}

const deleteFromDB = (docid) => {
      menuRef.doc(docid)
        .delete()
        .then(
          function() {
          console.log("Document successfully deleted!");
          }
        )
        .catch((error) => {
            alert(error)
        });
}


const addCategory = (sectionName,sectionTag) => {
    if (sectionName) {
      if (menuItems.map(k => k.title).indexOf(sectionName) >=0) {
        alert('Category is existed.');
      } else {
      const timestamp = firebase.firestore.FieldValue.serverTimestamp();
      let docRef = menuRef.doc()
      let newSection = {title:sectionName,data:[{id:docRef.id,list:[]}],sectionTag:sectionTag,'lastUpdated':timestamp,id:docRef.id}

      docRef
        .set(newSection)
        .then(_doc => {
          setMenuItems([...menuItems,newSection])
        })
        .catch((error) => {
            alert(error)
        });

      }
    }
  }

const updateCategory = (sectionName,sectionTag,section) => {
    if (sectionName) {
      if (menuItems.map(k => k.title).filter(x => x !== section.title).indexOf(sectionName) >=0) {
        alert('Category is existed.');
      } else {
        const timestamp = firebase.firestore.FieldValue.serverTimestamp();
        const newSection = {...section,title:sectionName,sectionTag:sectionTag,'lastUpdated':timestamp}

        let newItems = [...menuItems]
        let sectionIndex = menuItems.map(function(x) {return x.id; }).indexOf(section.id);

        newItems[sectionIndex] = newSection
        setMenuItems(newItems)

        menuRef.doc(section.id)
        .update({title:sectionName,sectionTag:sectionTag,'lastUpdated':timestamp})
        .then(_doc => {
          console.log('category updated')
        })
        .catch((error) => {
            alert(error)
        });

      }
    }
  }


const handleHeaderPress = (sectionData) => {
setSelectedSection(sectionData)
setSectionMode('Update')
setVisible(true)
}


const renderHeader = ({section}) => (
     <Layout style={{flex:1,flexDirection:'row',justifyContent:'space-between',alignItems:'center'}} level='2'>

    <Button 
      style={{marginLeft:isSmallScreen? 10:20}}
      size='tiny'
      status='info' 
      onPress={() => navigation.navigate('Item',
        { sectionID:section.id, 
          index: section.data[0].list.length, 
          id:uuidv4(),
          onReturn: (item) => {
            updateItem(item)
          }
        })}>
      Add an item 
      </Button>

     <View style={{flex:1,flexDirection:'row',justifyContent:'center',alignItems:'center'}}>

    {isSmallScreen ?  
     <Text status='basic' category='s1'>{section.sectionTag && `[${section.sectionTag}] `}{section.title}</Text>
     :
     <Text status='basic' category='h4'>{section.sectionTag && `[${section.sectionTag}] `}{section.title}</Text>
    }
      <Button 
      size='small'
      status='info' 
      appearance='ghost'
      style={{margin:0}}
      onPress={()=>handleHeaderPress(section)}>
      Edit
      </Button>

      <Button 
      size='small'
      status='info' 
      appearance='ghost'
      style={{margin:0}}
      onPress={()=>handleSectionDelete(section)}>
      Delete
      </Button>
      </View>

      </Layout>
);




const renderItemFooter = (tags) => (
      <View style={{flexDirection: 'row-reverse'}}>
      {
     tags && tags.map(
              (c,i) => 
              <Image key={i} source={{uri: require(`../../../assets/${c}-selected.png`)}} style={{float:'center',width: 15, height: 15}}/>
              )
     }
     </View> 
  );


const renderItemFlatList = ({item,index,section}) => { 
  return (
  <FlatList
            data={item.list}
            numColumns={nCol}
            renderItem={({item,index})=>renderItem(item,index,section)}
            extraData={{item:item,section:section}}
            keyExtractor={(item, index) => item.id}
        />
        )
 }

const renderItem = (item,index,section) => { 
  const itemLabel = section.sectionTag ? `[${section.sectionTag}${index + 1}] ${item.name}` : item.name

  return (

          <Card style={{width:cardWidth,margin:10,paddingTop:5,paddingLeft:5,paddingRight:5}}
            footer={() => renderItemFooter(item.tag)}
            onPress={() => navigation.navigate('Item',
                          { sectionID:section.id, 
                            index: index, 
                            id:item.id,
                            item:item,
                              onReturn: (item) => {
                              updateItem(item)
                            }
                          })}
            >
            <Layout level='1' style={{flex: 1,flexDirection: 'column',alignItems:'space-between',justifyContent: 'space-between'}}>

                <View style={{height:34,flex: 1,flexDirection: 'row',justifyContent: 'space-between'}}>
                  <Text numberOfLines={2} category='s1'> {itemLabel} ${item.price} </Text>
                  <MaterialIcons name='delete' size={20} onPress={()=>deleteItemById(item,section)}/>     
                  </View>

              <Divider/>

               { item.uri ?  (
                    <Image source={{uri: item.uri}} style={{alignSelf:'center',margin:10,width: 100, height: 100}}/>
                    )
                     : (
                    <Image source={{uri:require('../../../assets/noImage.png')}} style={{alignSelf:'center',margin:10,width: 100, height: 100}}/>
                    )
                }
                
              <Divider/>
               <View style={{flexDirection: 'column',justifyContent: 'flex-end'}}>
                         <Text style={styles.text} category='label'>Description</Text>
                         <Text style={[styles.text,{height:34}]} numberOfLines={2} ellipsizeMode='tail' appearance='hint' category='p1'>{item.description}</Text>
               </View>
                    
            </Layout>
          </Card>



);
}


if (loading) {  
  return ( 
      <ThemeActivityIndicator /> 
  ) 
}

const handleOnSubmit = (mode,sectionName,sectionTag,section) => {
  setVisible(false)

    if (mode == 'Submit') {
      addCategory(sectionName,sectionTag)    
    } else if (mode == 'Update') {
      updateCategory(sectionName,sectionTag,section) 
    }
}

const handleOnCancel = (e) => {
    if (e) {
    setVisible(false)
    }
  }


const addSectionIcon = (props) => (
  <Button 
  size='small'
  status='info'
  onPress={() => 
    {
      setSelectedSection(null)
      setSectionMode('Submit')
      setVisible(true)
      }
      } 
    >
  Add Category
  </Button>
);


const TitleText = () => (
  <>
  {isSmallScreen ?  
  <Text category='h5' style={styles.text}>Menu</Text>
  :
  <Text category='h1' style={styles.text}>Menu</Text>
  }
  </>
  )

 const renderRightActions = () => (
    <React.Fragment>
      <TopNavigationAction icon={addSectionIcon}/>
    </React.Fragment>
  );

  return (
      <SafeAreaView style={{ flex: 1 }}>

      <TopNavigation
        alignment='center'
        title={props => <TitleText/>}
        accessoryLeft={renderRightActions}
      />

          <KeyboardAwareScrollView         
            style={{ flex: 1, width: '100%' }}
            keyboardShouldPersistTaps="always">

            <Layout style={[styles.container,{padding:isSmallScreen ? 5:20}]} level='2'>
            
            {visible && 
            <Modal
            visible={visible}
            backdropStyle={styles.backdrop}
            onBackdropPress={()=>setVisible(false)}>

            <SectionModal 
            section={selectedSection}
            submissionMode={sectionMode}
            onSubmit={handleOnSubmit} 
            onCancel={handleOnCancel}
            />
            </Modal>

          }

            <SectionList
              sections={menuItems}
              renderItem={renderItemFlatList}
              renderSectionHeader={renderHeader}
              keyExtractor={(item) => item.id}
            />


          <Modal
          visible={alertVisible}
          backdropStyle={styles.backdropStyle}>

          <Card status='basic'>
            <Layout style={{padding:10}}>
                <Text style={{paddingBottom:10}} status='basic' category='h4'>Delete category</Text>
                <Text status='basic' category='p'>Do you want to delete the whole category?</Text>
                <Text status='basic' category='p'>You cannot undo this action.</Text>

                <Layout style={[styles.footerContainer,{margin:10}]}>
                
                    <Button 
                     size='small' appearance='ghost' onPress={()=>setAlertVisible(false)}>
                      CANCEL
                     </Button>

                    <Button 
                    size='small' appearance='ghost' onPress={()=>{setAlertVisible(false);deleteSection()}}>
                    DELETE
                    </Button>

                </Layout>
             </Layout>  
              </Card>

          </Modal>

        </Layout>
        </KeyboardAwareScrollView>
      </SafeAreaView>

    );
}



function MenuStackScreen(props) {
  return (
      <MenuStack.Navigator headerMode='none'>
        <MenuStack.Screen name="Menu"   
        initialParams={{ uid: props.extraData }}
        component={MenuScreen} 
        options={{headerMode:'float'}}/>

        <MenuStack.Screen name="Item"   
        initialParams={{ uid: props.extraData }}
        component={MenuItemScreen} />
      </MenuStack.Navigator>
  );
}

export default MenuStackScreen;