import { firebase } from '../firebase/config'
import * as ImagePicker from 'expo-image-picker';
import * as ImageManipulator from 'expo-image-manipulator';

const resizeImage = async (result,path) => {
    const thumbnail = await ImageManipulator.manipulateAsync(
      result.uri,
      [{resize: {width:120,height:120}}],
      { compress: 1, format: ImageManipulator.SaveFormat.JPEG }
    );
    return thumbnail;
  }

const uploadImage = async (uri,path) => {
    // upload
    const storage = firebase.storage().ref();
    const response = await fetch(uri);
    const blob = await response.blob();

    var metadata = {
    contentType: 'image/jpeg',
    };
    const ref = storage.child(path);
      ref
      .put(blob,metadata)
      .then((snapshot) => {
      console.log('Uploaded the image');
      // return snapshot
        // // get downloadURL
        // snapshot.ref.getDownloadURL()
        // .then(function(downloadURL) {
        //   console.log('Uploaded the image',downloadURL);
        //   return downloadURL
        // })
       })
      .catch(function(error) {
        console.log(error);
      });
  }

const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 4],
      quality: 1,
    });
    if (!result.cancelled) {
      return result;
    }
  };


export {
   uploadImage,
   pickImage,
   resizeImage
};