import React from 'react';

// Icon
function CustomMarker(props) { 
  const { color, name, id } = props;
  const onClick = () => {
    props.onClick(props)
  };


    return (
    <div className="customMarker" title={name}  onClick={onClick}>
    <svg  xmlns="http://www.w3.org/2000/svg" fillOpacity="1" width="34px" height="44px" viewBox="0 0 34 44" stroke="white" strokeWidth="2">
      <g fill="#D93F2A" >
      <title id="title">icon</title>
     <path d="M16.3223077,0.0423076923 C20.3690605,0.0423076923 24.0709572,1.51881206 26.9186662,3.96248911 C26.076862,4.77779899 25.5538462,5.91974412 25.5538462,7.18384615 C25.5538462,9.66063084 27.5616769,11.6684615 30.0384615,11.6684615 C30.6692288,11.6684615 31.2695807,11.5382382 31.8140991,11.3032098 C32.3257878,12.8843575 32.6023077,14.5710402 32.6023077,16.3223077 C32.6023077,16.7454228 32.5861664,17.1647679 32.554466,17.5797608 C32.5444429,17.7227817 32.5311373,17.8734049 32.5125461,18.0380274 C32.3642834,19.456641 32.0338523,20.8198724 31.5453114,22.1052018 C31.4888354,22.2783322 31.4286736,22.4572898 31.3652794,22.6410508 C29.7909889,27.2044484 16.2741344,43.4840504 16.2741344,43.4840504 C7.21531308,32.9681794 2.20708836,26.1198326 1.24946021,22.9390097 C0.330137197,19.8854198 -0.0818742812,17.7566192 0.0134257788,16.5526079 L0.027980534,16.4069231 L0.042998968,16.4063077 L0.0423076923,16.3223077 C0.0423076923,7.43689074 7.16061952,0.214044594 16.0056873,0.0453250889 L16.3223077,0.0423076923 Z M16.2741344,10.1453846 C12.8159822,10.1453846 10.0125959,12.9487709 10.0125959,16.4069231 C10.0125959,19.8650753 12.8159822,22.6684615 16.2741344,22.6684615 C19.7322866,22.6684615 22.5356728,19.8650753 22.5356728,16.4069231 C22.5356728,12.9487709 19.7322866,10.1453846 16.2741344,10.1453846 Z" id="Combined-Shape" />
    </g>
    </svg>
    </div>
  )

}
export default CustomMarker;


