import React, { useState,useEffect } from 'react';
import {Card, Button, Text, Layout,Divider } from '@ui-kitten/components';
import { firebase } from '../../firebase/config'
import {ActivityIndicator,Dimensions,SafeAreaView, SectionList, Image, View, FlatList} from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const MenuList = ({uid}) => {
  const menuRef = firebase.firestore().collection('users/'+uid+'/menu')
  const [menuItems, setMenuItems ] = useState(null);
  const [loading, setLoading] = useState(true)

  const storage = firebase.storage().ref();
  console.log(menuItems)
  useEffect(() => {

    const retrieveItemsFromDB = async () => {
      const dbmenuItems = []
      let allSections = await menuRef.get();
      if (allSections.length==0) 
        {
          setMenuItems([])
          return
        }
      for(const doc of allSections.docs) {
          const dbsection = doc.data()
          let data = []
          let allItems = await menuRef.doc(doc.id).collection("data").get()

          for (const item of allItems.docs) {
            let itemData = item.data()
            // loading raw image source
            if ("imagePath" in itemData) {
              const ref = storage.child(itemData.imagePath);
              const url = await ref.getDownloadURL()
              itemData['uri'] = url
            }
            data.push(itemData)
          }
          data.sort((a, b) => a.index - b.index)
          dbsection.data = data
          dbmenuItems.push(dbsection)
          }

        dbmenuItems.sort((a, b) => a.index - b.index);
        setMenuItems(dbmenuItems)
        setLoading(false)
    }

    try {
        retrieveItemsFromDB();
    } catch (e) {
        console.error(e);
        setLoading(false)

    } 
 

 },[])


const renderItemFooter = (tags) => (
      <View style={{flexDirection: 'row-reverse'}}>
      {
     tags && tags.map(
              (c,i) => 
              <Image key={i} source={{uri: require(`../../../assets/${c}-selected.png`)}} style={{float:'center',width: 15, height: 15}}/>
              )
     }
     </View> 
  );

const renderItem = (item,index,section) => { 
  const itemLabel = section.sectionTag ? `[${section.sectionTag}${index + 1}] ${item.name}` : item.name

  return (

          <Card style={{width:180,margin:10,paddingTop:5,paddingLeft:5,paddingRight:5}}
            footer={() => renderItemFooter(item.tag)}
            onPress={() => navigation.navigate('Item',
                          { sectionID:section.id, 
                            index: index, 
                            id:item.id,
                            item:item,
                              onReturn: (item) => {
                              updateItem(item)
                            }
                          })}
            >
            <Layout level='1' style={{flex: 1,flexDirection: 'column',alignItems:'space-between',justifyContent: 'space-between'}}>

                <View style={{height:34,flex: 1,flexDirection: 'row',justifyContent: 'space-between'}}>
                  <Text numberOfLines={2} category='s1'> ${itemLabel} ${item.price} </Text>
                  </View>

              <Divider/>

               { item.uri ?  (
                    <Image source={{uri: item.uri}} style={{alignSelf:'center',margin:10,width: 100, height: 100}}/>
                    )
                     : (
                    <Image source={{uri:require('../../../assets/noImage.png')}} style={{alignSelf:'center',margin:10,width: 100, height: 100}}/>
                    )
                }
                
              <Divider/>
               <View style={{flexDirection: 'column',justifyContent: 'flex-end'}}>
                         <Text  category='label'>Description</Text>
                         <Text style={{height:34}} numberOfLines={2} ellipsizeMode='tail' appearance='hint' category='p1'>{item.description}</Text>
               </View>
                    
            </Layout>
          </Card>
);
}


if (loading) {  
  return ( 
  <View style={{flex:1,width:200,height:200,alignSelf:'center'}}>
  <Text category='h4' style={{alignSelf:'center',color:"white"}}>Menu</Text> 
  <ActivityIndicator size="large" color="white"/> 
  </View>
  ) 
}



return (
    <>
 { menuItems && 
    <SafeAreaView>

    { (menuItems.length==0) ? 
    <Card>
    <Text status='basic' category='h6' >No menu currently available</Text> 
    </Card>
    : 
     <View style={{ flex: 1,height:500}}>
    <Text status='basic' category='h4' style={{alignSelf:'center',color:'white'}}>Menu</Text> 

     <KeyboardAwareScrollView style={{ flex: 1, width: '100%'}}       
      keyboardShouldPersistTaps="always">
      <Card>
            <Layout style={{ flex: 1, padding: 5 }} >
            <SectionList
            sections={menuItems}
            renderItem={({ item,index,section }) => renderItem(item,index,section)}
            renderSectionHeader={({ section: { title } }) => (
              <Text status='basic' category='h4' >{title}</Text>
            )}
            keyExtractor={(item, index) => item + index}
          /> 
          </Layout>
      </Card>
      </KeyboardAwareScrollView>
      </View>
    }
      </SafeAreaView>
  }
  </>
 )
}


export default MenuList;
